import React, { useEffect } from 'react';
import SectionTitle from 'components/SectionTitle';
import HorizontalScroller from 'components/HorizontalScroller';
import useFeature from 'hooks/useFeature';
import { useDispatch, useSelector } from 'react-redux';
import { calendarActions, calendarSelectors } from 'redux/calendar';
import NavLink from 'components/NavLink';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import EventBoxSmall from 'components/EventBoxSmall';
import Card from 'components/Card';
import CardContent from 'components/CardContent';

const EventBoxMapper = ({ id }) => {

  const event = useSelector(calendarSelectors.getEventSelector)(id);

  if (!event) {
    return null;
  }

  return (
    <Card style={{ minWidth: 300, maxWidth: '100%' }}>
      <CardContent>
        <EventBoxSmall
          id={event.id}
          name={event.name}
          cover={event.cover}
          startAt={event.startAt}
          endAt={event.endAt}
          allDay={event.allDay}
          hasTargets={event.hasTargets}
          targetUsersCount={event.targetUsersCount}
        />
      </CardContent>
    </Card>
  );
};

EventBoxMapper.propTypes = {
  id: PropTypes.any.isRequired,
};

const CalendarWidgetSmall = ({ seeMore, ...args }) => {

  const dispatch = useDispatch();
  const { t } = useTranslation(['calendar']);
  const { enabled } = useFeature('calendar');

  const { eventIds } = useSelector(state => {
    const upcoming = calendarSelectors.getUpcomingEvents(state);
    return {
      ...upcoming,
      eventIds: upcoming.eventIds.slice().splice(0, 3),
    };
  });

  useEffect(() => {
    if (enabled) {
      dispatch(calendarActions.getEvents({ status: 'upcoming' }));
    }
  }, [dispatch, enabled]);

  if (!eventIds || eventIds.length === 0 || !enabled) {
    return null;
  }

  return (
    <div {...args}>
      <SectionTitle seeMore={seeMore}>
        {t('calendarWidgetSmall.title')}
      </SectionTitle>
      <HorizontalScroller>
        {eventIds.splice(0, 3).map(id => <EventBoxMapper key={id} id={id} />)}
      </HorizontalScroller>
    </div>
  );
};

CalendarWidgetSmall.propTypes = {
  seeMore: PropTypes.object,
};

CalendarWidgetSmall.defaultProps = {
  seeMore: {
    component: NavLink,
    to: '/calendar',
  },
};

export default CalendarWidgetSmall;
