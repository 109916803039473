import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Grid from 'components/Grid';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import Button from 'components/Button';
import Checkbox from '@mui/material/Checkbox';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Form from 'components/FinalForm/Form';
import uniqueId from 'lodash/uniqueId';
import FlipMove from 'react-flip-move';
import { useSelector } from 'react-redux';
import { authSelectors } from 'redux/auth';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import { formatString } from 'services/date-fns';
import FormField from './FinalForm/Fields/FormField';
import { PostContext } from './Post';
import TrashIcon from './Icons/Trash';

const Wrapper = styled('div')`
  ${({ white }) => white && css`
    padding: 2rem 1.5rem;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
                0px 2px 6px rgba(0, 0, 0, 0.05);
  `}
`;

const WrapperTable = styled('div')`
  display: table;
  width: 100%;
`;

const WrapperTableBodyComponent = React.forwardRef(({ disableAnimations, ...rest }, ref) => disableAnimations ? <div {...rest} ref={ref} /> : <FlipMove {...rest} ref={ref} />);
const WrapperTableBody = styled(WrapperTableBodyComponent)`
  position: relative;
  display: table-row-group;
  overflow-anchor: none; // To fix chrome scroll anchoring
  width: 100%;
`;

WrapperTableBodyComponent.propTypes = {
  disableAnimations: PropTypes.bool,
};

const WrapperRow = styled('div')`
  display: table-row;
`;

const WrapperCell = styled('div')`
  display: table-cell;
  vertical-align: middle;

  padding: 0.25rem 0;

  ${({ fullWidth }) => fullWidth && 'width: 100%;'}
`;

const OptionProgress = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: #eee;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  transition: width 500ms ease-out;
`;

const Option = styled('div')`
  background: #f9f9f9;
  border-radius: 4px;
  width: 100%;

  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

const Responders = styled('div')`
  margin-left: 1rem;
  text-align: center;
  color: #666;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ hidden }) => hidden && css`
    display: none;
  `}

  ${({ onClick }) => onClick && css`
    cursor: pointer;
  `}
`;

const RespondersNumber = styled('div')`
  width: 100%;
  font-weight: 600;
`;

const RespondersText = styled('div')`
  font-size: 0.8rem;
`;

const Question = styled('div')`
  font-weight: 600;
`;

const Subtitle = styled('div')`
  color: #666;
  font-size: .9rem;
`;

const TitleGrid = styled(Grid)`
  margin-bottom: 0.5rem;
`;

const AddedBy = styled('span')`
  display: block;
  color: #888;
  font-weight: 500;
  font-size: 11px;
`;

const formFieldStyles = {
  margin: 0,
  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: 2,
  },
  '& .MuiInputBase-input': {
    boxSizing: 'border-box',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    minHeight: 44,
  },
};

const checkboxStyles = {
  'span:nth-of-type(2)': {
    flexBasis: '100%', /* flex-basis needed for IE11. Otherwise long options overflow the table. (IE11 Table + flex issue ) */
  },
  '& .MuiFormControlLabel-root': {
    minHeight: 42,
    marginRight: 0,
    padding: '0 0.75rem',
  },
  '& .MuiFormControlLabel-root > span': {
    padding: '0.5rem',
  },
};

const SubmitButton = styled(Button)`
  height: 100%;
  min-height: 44px;
`;

const RemovePollOptionButton = styled(IconButton)`
  margin: 0 0.5rem;
  transition: opacity 300ms;

  opacity: .4;
  div:hover > &,
  &[disabled] {
    opacity: 1;
  }
`;

const localeFind = (a, b) => a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0;

const getValidator = options => values => {

  if (values.option
    && options
    && options.findIndex(({ option }) => localeFind(option.trim(), values.option.trim())) > -1) {
    return {
      option: 'Option already exists',
    };
  }

  return {};
};

const AddOptions = ({ onOptionAdd, disabled, options }) => {

  const { t } = useTranslation(['component']);

  const onSubmit = ({ option }, form) => {
    if (onOptionAdd) {
      onOptionAdd(option).then(() => form.restart());
    }
  };

  const onKeyPress = (e, handleSubmit) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <WrapperRow>
      <WrapperCell>
        <Form onSubmit={onSubmit} validate={getValidator(options)} validateOnSubmit>
          {({ handleSubmit, submitting, values }) => (
            <Grid container spacing={1} wrap="nowrap" alignItems="flex-start">
              <Grid item xs zeroMinWidth>
                <FormField
                  // classes={formFieldClasses}
                  sx={formFieldStyles}
                  onKeyPress={e => onKeyPress(e, handleSubmit)}
                  disabled={disabled}
                  name="option"
                  placeholder={t('component:poll.addOption.optionPlaceholder')}
                  variant="outlined"
                  margin="none"
                />
              </Grid>
              <Grid item>
                <SubmitButton type="submit" onClick={handleSubmit} disabled={!values.option || submitting || disabled}>{t('component:poll.addOption.submit')}</SubmitButton>
              </Grid>
            </Grid>
          )}
        </Form>
      </WrapperCell>
      <WrapperCell />
    </WrapperRow>
  );
};

AddOptions.propTypes = {
  onOptionAdd: PropTypes.func,
  disabled: PropTypes.bool,
  options: PropTypes.array,
};

const RemovePollOption = ({ onOptionRemove, option, disabled }) => {
  return (
    <RemovePollOptionButton
      disabled={disabled}
      onClick={() => {
        onOptionRemove(option);
      }}
    >
      <TrashIcon fontSize="small" />
    </RemovePollOptionButton>
  );
};

RemovePollOption.propTypes = {
  onOptionRemove: PropTypes.func,
  option: PropTypes.object,
  disabled: PropTypes.bool,
};

const Poll = ({ disabled, addOptions, question, options, maxVotes, votesCount, onOptionClick, onVotesClick, onOptionAdd, onOptionRemove, pollActions, closeAt, closed, disableAnimations, anonymous, viewVotes, viewVoters }) => {

  const { t } = useTranslation(['component']);

  const { id: meId } = useSelector(authSelectors.getMeUser);

  // Quickfix. Not the correct place, but not sure what else
  // to do with the red background in general, or where's
  // a better place to put this logic.
  const { isImportant, isCompact } = useContext(PostContext);

  const onChange = option => {
    if (!onOptionClick) {
      return;
    }
    onOptionClick(option);
  };

  return (
    <Wrapper white={isImportant && isCompact}>
      <TitleGrid container spacing={2} wrap="nowrap" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container direction="column" spacing={1} wrap="nowrap">
            <Grid item><Question>{question}</Question></Grid>
            {(closeAt || anonymous) && (
              <Grid item>
                <Subtitle>
                  {closed && <>{t('poll.closed')}</>}
                  {closeAt && !closed && (
                    t('poll.acceptingVotesUntil', { date: formatString(closeAt, 'PPPp') })
                  )}
                  {closeAt && anonymous && <>&#65372;</>}
                  {anonymous && <>{t('poll.shortAnonymousExplanation')}</>}
                </Subtitle>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item hidden={!pollActions}>
          {pollActions}
        </Grid>
      </TitleGrid>

      <WrapperTable>
        <WrapperTableBody disableAnimations={disableAnimations}>
          {options.map(option => (
            <WrapperRow key={option.id || uniqueId()}>
              <WrapperCell fullWidth>
                <Option>
                  <OptionProgress style={option.votesCount > 0 ? { width: `${(option.votesCount / votesCount) * 100}%` } : { width: 0 }} />
                  <FormControl fullWidth margin="none" sx={checkboxStyles}>
                    <FormControlLabel
                      control={maxVotes === 1
                        ? <Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} />
                        : <Checkbox />}
                      type="checkbox"
                      label={(
                        <>
                          {option.option}
                          {addOptions && option.user && (
                            <AddedBy>{+option.user.id === +meId ? t('poll.addedByYou') : t('poll.addedBy', { name: option.user.name })}</AddedBy>
                          )}
                        </>
                      )}
                      checked={option.voted}
                      onChange={() => onChange(option)}
                      disabled={disabled || closed}
                    />
                  </FormControl>
                  {option?.permissions?.delete && <RemovePollOption onOptionRemove={onOptionRemove} option={option} disabled={disabled} />}
                </Option>
              </WrapperCell>
              <WrapperCell>
                <Responders hidden={!option.votesCount || !viewVotes} onClick={onVotesClick && viewVoters ? () => onVotesClick(option) : undefined}>
                  <RespondersNumber>{option.votesCount}</RespondersNumber>
                  <RespondersText>{t('poll.votesCountSuffix', { count: option.votesCount })}</RespondersText>
                </Responders>
              </WrapperCell>
            </WrapperRow>
          ))}
        </WrapperTableBody>

        {addOptions && <AddOptions options={options} onOptionAdd={onOptionAdd} disabled={disabled || closed} />}

      </WrapperTable>
    </Wrapper>
  );
};

Poll.propTypes = {

  pollActions: PropTypes.node,

  onOptionClick: PropTypes.func,
  onVotesClick: PropTypes.func,
  onOptionAdd: PropTypes.func,
  onOptionRemove: PropTypes.func,

  disabled: PropTypes.bool,
  disableAnimations: PropTypes.bool,

  viewVotes: PropTypes.bool,
  viewVoters: PropTypes.bool,

  closed: PropTypes.bool,
  closeAt: PropTypes.string,
  anonymous: PropTypes.bool,
  addOptions: PropTypes.bool,
  maxVotes: PropTypes.number,
  votesCount: PropTypes.number,

  question: PropTypes.string.isRequired,

  options: PropTypes.arrayOf(
    PropTypes.shape({
      option: PropTypes.string.isRequired,
      permission: PropTypes.object,
      user: PropTypes.object,
      votesCount: PropTypes.number,
      voted: PropTypes.bool,
    }),
  ),
};

Poll.defaultProps = {
  viewVotes: true,
};

export default Poll;
