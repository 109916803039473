import React, { useMemo } from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from 'components/Grid';
import Avatar from 'components/Avatar';
import CommentContext from './CommentContext';

const Bubble = styled('div')`
  background: #f5f5f5;
  border-radius: ${({ isMe }) => isMe ? '8px 4px 8px 8px' : '4px 8px 8px 8px'};

  /* Disable margin collapse from top and bottom edges */
  padding-top: 0.05px;
  padding-bottom: 0.05px;
`;

const CommentWrapper = ({ avatarProps, compact, isMe, children, ...rest }) => {

  const smallScreen = useMediaQuery('(max-width: 499px)');

  const size = compact || smallScreen ? 24 : 40;
  const spacing = compact || smallScreen ? 1 : 2;

  const context = useMemo(() => ({
    isMe,
  }), [isMe]);

  return (
    <CommentContext.Provider value={context}>
      <Grid container spacing={spacing} direction={isMe ? 'row-reverse' : 'row'} wrap="nowrap" {...rest}>
        <Grid item>
          <Avatar size={size} {...avatarProps} />
        </Grid>
        <Grid item xs zeroMinWidth>
          <Bubble isMe={isMe}>
            {children}
          </Bubble>
        </Grid>
      </Grid>
    </CommentContext.Provider>
  );

};

CommentWrapper.propTypes = {
  avatarProps: PropTypes.shape(Avatar.PropTypes),
  compact: PropTypes.bool,
  isMe: PropTypes.bool,
};

CommentWrapper.defaultProps = {
  isMe: false,
  compact: false,
};

export default CommentWrapper;
