import React from 'react';
import TextField from '@mui/material/TextField';
import MuiAutocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import withFlatArgs from 'components/FinalForm/withFlatArgs';
import { Field } from 'react-final-form';

const showPlaceholder = value => Array.isArray(value)
  ? value.length === 0
  : !value;

const Autocomplete = React.forwardRef(({
  value, onChange, onBlur, onFocus, fullWidth,
  label, placeholder, error, helperText, InputProps, inputRef,
  ...rest }, ref) => {

  const { t } = useTranslation('component');

  const placeholderText = placeholder || t('component:autoComplete.select');

  return (
    <MuiAutocomplete
      value={value}
      onChange={(e, next) => onChange(next)}

      fullWidth={fullWidth}

      noOptionsText={t('component:autoComplete.noOptions')}
      loadingText={t('component:autoComplete.loadingMessage')}

      ref={ref}

      renderInput={props => (
        <TextField
          inputRef={inputRef}

          margin="none"
          autoComplete="off"

          label={label}
          placeholder={showPlaceholder(value) ? placeholderText : undefined}

          error={error}
          helperText={helperText}

          onBlur={onBlur}
          onFocus={onFocus}

          {...props}

          InputProps={{
            ...props?.InputProps,
            ...InputProps,
          }}
        />
      )}

      {...rest}
    />
  );
});

Autocomplete.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,

  disableUnderline: PropTypes.bool,
  fullWidth: PropTypes.bool,
  multiple: PropTypes.bool,

  label: PropTypes.string,
  placeholder: PropTypes.string,

  error: PropTypes.bool,
  helperText: PropTypes.node,

  onBlur: PropTypes.func,
  onFocus: PropTypes.func,

  InputProps: PropTypes.object,
  inputRef: PropTypes.any,
  options: PropTypes.array,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
};

Autocomplete.defaultProps = {
  fullWidth: true,
};

const FinalFormComponent = withFlatArgs(Autocomplete, 'Autocomplete');
Autocomplete.FinalForm = (args) => <Field component={FinalFormComponent} {...args} />;

export default Autocomplete;
