import React, { useCallback, useState, useContext, useMemo, useEffect } from 'react';

import validator, { isRequired } from 'validators';
import PropTypes from 'prop-types';
import useVideoUploader from 'hooks/useVideoUploader';
import Form from 'components/FinalForm/Form';
import isEqual from 'lodash/isEqual';
import arrayMutators from 'final-form-arrays';
import { useField, useForm, useFormState } from 'react-final-form';
import { channelActions, channelSelectors } from 'redux/channel';
import { useDispatch, useSelector } from 'react-redux';
import PostFormContext from './PostFormContext';
import BaseView from './BaseView/BaseView';
import PollView from './PollView/PollView';
import useAlternatingContent from './useAlternatingContent';
import PollProvider from './PollView/PollProvider';
import QuizProvider from './QuizView/QuizProvider';
import QuizView from './QuizView/QuizView';
import SmsView from './SmsView/SmsView';
import SmsProvider from './SmsView/SmsProvider';

const postFormValidator = values => {

  // When some other content is present, body is no longer required
  const hasContent = (values.activity && values.activity.categoryId)
    || values.image
    || values.video
    || values.poll
    || values.quiz
    || (values.media?.length > 0)
    || (values.attachments?.length > 0);

  return validator({
    body: !hasContent ? isRequired : [],

    // Duplicate error to bodyHtml well. If field is not present, it won't have any impact
    ...(!hasContent && !values.body && {
      bodyHtml: isRequired,
    }),

    channelId: isRequired,
  })(values);
};

// QUICKFIX
// Alternating content removes view from the DOM and unregisters all fields, like a wizard form
// If fields are unregistered then they no longer have impact on "dirty" attribute
// Here we manually set the form to be considered dirty on certain conditions.
const DirtyHelperField = () => {

  const form = useForm();
  const { view } = useContext(PostFormContext);
  const { values } = useFormState({ subscription: { values: true } });

  useField('$dirty', {
    defaultValue: false,
    initialValue: false,
    subscription: {},
    beforeSubmit: () => form.change('$dirty', undefined),
  });

  useEffect(() => {
    const isDirty = false
      || view !== 'base' // 1. Form is considered dirty, When we're in any other view except initial ('base').
      || !!values.poll // 2. Form is considered dirty, when there's a poll added to the post.
      || !!values.quiz // 3. Form is considered dirty, when there's a quiz added to the post.
      || !!values.sms; // 4. Form is considered dirty, when there's a sms added to the post.

    form.change('$dirty', isDirty);
  }, [form, values.poll, values.quiz, values.sms, view]);

  return null;
};

const ChannelLoader = () => {
  const dispatch = useDispatch();

  const { values: { channelId } } = useFormState({ subscription: { values: true } });

  const channelSelector = useSelector(channelSelectors.getChannelSelector);

  useEffect(() => {
    const channel = channelSelector(channelId);

    if (channelId && !channel?.permissions) {
      dispatch(channelActions.getChannel({ channelId }));
    }
  }, [channelId, channelSelector, dispatch]);

  return null;
};

const PostForm = ({ disableChannelSelect, disableScheduling, editing, asDialog, onClose, children, ...rest }) => {

  const videoUploader = useVideoUploader();

  const validate = useCallback(postFormValidator, []);

  const [view, setView] = useState('base');

  const content = useAlternatingContent({
    base: BaseView,
    poll: PollView,
    quiz: QuizView,
    sms: SmsView,
  }, view);

  const style = useMemo(() => {
    const dialogWidth = {
      base: 680,
      poll: 500,
      quiz: 500,
      pin: 500,
      sms: 500,
    }[view] || 680;

    return asDialog
      ? { width: dialogWidth, maxWidth: '100%' }
      : {};
  }, [asDialog, view]);

  // Fix: Server loads groups and channels and this causes a form refresh
  // and loses all the values which where already changed.
  // With initialValuesEqual this doesn't seem to happen.
  // To test this, open a form, quickly toggle a button or write something.
  // Soon after all requests finish, something somewhere is updated and form
  // updates as well, and all values are lost... this prevents this
  const initialValuesEqual = isEqual;

  // No validate required for activity posting
  // Following happened on Safari trying to enter decimals for activity values
  // Safari changed the entered number "1.5" -> "1,5" and then started to
  // complain that a valid value must be entered.
  // Might be that something else changes the decimal.
  // For now noValidate + [type="number"] seem to work.
  const noValidate = true;

  // Given the design of our form, fields get added and removed constantly
  // Keep all the values when this happens.
  // When a value is no longer needed, we need to clear it manually.
  const keepFormStateOnFieldUnmount = true;

  return (
    <PostFormContext.Provider value={{ asDialog, onClose, videoUploader, editing, disableChannelSelect, disableScheduling, view, setView }}>
      <PollProvider>
        <QuizProvider>
          <SmsProvider>
            <Form validate={validate} mutators={arrayMutators} initialValuesEqual={initialValuesEqual} keepFormStateOnFieldUnmount={keepFormStateOnFieldUnmount} {...rest}>
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} style={style} noValidate={noValidate}>
                  <DirtyHelperField />
                  <ChannelLoader />
                  {content}
                </form>
              )}
            </Form>
          </SmsProvider>
        </QuizProvider>
      </PollProvider>
    </PostFormContext.Provider>
  );
};

PostForm.propTypes = {
  channelId: PropTypes.any,
  editing: PropTypes.bool,
  initialValues: PropTypes.object,

  onClose: PropTypes.func,
  disableChannelSelect: PropTypes.bool,
  disableScheduling: PropTypes.bool,

  asDialog: PropTypes.bool,
};

PostForm.defaultProps = {
  editing: false,
  asDialog: false,
};

export default PostForm;
