import combineReducers from 'combine-reducers-global-state';
import types from './types';
import { addToPagination } from '../helpers/pagination';


const coursesAnalytics = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getCoursesAnalyticsSuccess:
      return addToPagination(state, null, payload, 'courses');
    default:
      return state;
  }
};

const courseMembersAnalytics = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getCourseMembersAnalyticsSuccess:
      return addToPagination(state, payload.courseId, payload, 'members');
    default:
      return state;
  }
};

const courseVariantQuestionsAnalytics = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getCourseVariantQuestionsAnalyticsSuccess:
      return { ...state, [payload.courseVariantId]: payload.data };
    default:
      return state;
  }
};

const courseLessonsAnalytics = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getCourseLessonsAnalyticsSuccess:
      return { ...state, [payload.courseId]: payload.data };
    default:
      return state;
  }
};

const lessonQuestionsAnalytics = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getLessonQuestionsAnalyticsSuccess:
      return { ...state, [payload.lessonId]: payload.data };
    default:
      return state;
  }
};

const membersAnalytics = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getMembersAnalyticsSuccess:
      return addToPagination(state, null, payload, 'members');
    default:
      return state;
  }
};

const memberCoursesAnalytics = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getMemberCoursesAnalyticsSuccess:
      return addToPagination(state, payload.memberId, payload, 'courses');
    default:
      return state;
  }
};

const memberCourseAnalytics = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getMemberCourseAnalyticsSuccess:
      return addToPagination(state, `${payload.memberId}-${payload.courseId}`, payload, 'questions');
    default:
      return state;
  }
};

const labelsAnalytics = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getLabelsAnalyticsSuccess:
      return addToPagination(state, null, payload, 'labels');
    default:
      return state;
  }
};

const labelCoursesAnalytics = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getLabelCoursesAnalyticsSuccess:
      return addToPagination(state, payload.labelId, payload, 'courses');
    default:
      return state;
  }
};

const learningAnalyticsReducers = combineReducers({
  coursesAnalytics,
  courseMembersAnalytics,
  courseLessonsAnalytics,
  courseVariantQuestionsAnalytics,
  lessonQuestionsAnalytics,
  membersAnalytics,
  memberCoursesAnalytics,
  memberCourseAnalytics,
  labelsAnalytics,
  labelCoursesAnalytics,
});

export default { learningAnalytics: learningAnalyticsReducers };
