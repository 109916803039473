import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {
  getCoursesAnalytics: data => createAction(types.getCoursesAnalytics, data),
  getCoursesAnalyticsSuccess: data => createAction(types.getCoursesAnalyticsSuccess, data),
  getCoursesAnalyticsFailure: error => createAction(types.getCoursesAnalyticsFailure, error),

  getCourseMembersAnalytics: data => createAction(types.getCourseMembersAnalytics, data),
  getCourseMembersAnalyticsSuccess: data => createAction(types.getCourseMembersAnalyticsSuccess, data),
  getCourseMembersAnalyticsFailure: error => createAction(types.getCourseMembersAnalyticsFailure, error),

  getCourseMembersAnalyticsDownload: data => createAction(types.getCourseMembersAnalyticsDownload, data),
  getCourseMembersAnalyticsDownloadSuccess: data => createAction(types.getCourseMembersAnalyticsDownloadSuccess, data),
  getCourseMembersAnalyticsDownloadFailure: error => createAction(types.getCourseMembersAnalyticsDownloadFailure, error),

  getCourseLessonsAnalytics: data => createAction(types.getCourseLessonsAnalytics, data),
  getCourseLessonsAnalyticsSuccess: data => createAction(types.getCourseLessonsAnalyticsSuccess, data),
  getCourseLessonsAnalyticsFailure: error => createAction(types.getCourseLessonsAnalyticsFailure, error),

  getCourseLessonsAnalyticsDownload: data => createAction(types.getCourseLessonsAnalyticsDownload, data),
  getCourseLessonsAnalyticsDownloadSuccess: data => createAction(types.getCourseLessonsAnalyticsDownloadSuccess, data),
  getCourseLessonsAnalyticsDownloadFailure: error => createAction(types.getCourseLessonsAnalyticsDownloadFailure, error),

  getCourseVariantQuestionsAnalytics: data => createAction(types.getCourseVariantQuestionsAnalytics, data),
  getCourseVariantQuestionsAnalyticsSuccess: data => createAction(types.getCourseVariantQuestionsAnalyticsSuccess, data),
  getCourseVariantQuestionsAnalyticsFailure: error => createAction(types.getCourseVariantQuestionsAnalyticsFailure, error),

  getLessonQuestionsAnalytics: data => createAction(types.getLessonQuestionsAnalytics, data),
  getLessonQuestionsAnalyticsSuccess: data => createAction(types.getLessonQuestionsAnalyticsSuccess, data),
  getLessonQuestionsAnalyticsFailure: error => createAction(types.getLessonQuestionsAnalyticsFailure, error),

  getMembersAnalytics: data => createAction(types.getMembersAnalytics, data),
  getMembersAnalyticsSuccess: data => createAction(types.getMembersAnalyticsSuccess, data),
  getMembersAnalyticsFailure: error => createAction(types.getMembersAnalyticsFailure, error),

  getMemberCoursesAnalytics: data => createAction(types.getMemberCoursesAnalytics, data),
  getMemberCoursesAnalyticsSuccess: data => createAction(types.getMemberCoursesAnalyticsSuccess, data),
  getMemberCoursesAnalyticsFailure: error => createAction(types.getMemberCoursesAnalyticsFailure, error),

  getMemberCoursesAnalyticsDownload: data => createAction(types.getMemberCoursesAnalyticsDownload, data),
  getMemberCoursesAnalyticsDownloadSuccess: data => createAction(types.getMemberCoursesAnalyticsDownloadSuccess, data),
  getMemberCoursesAnalyticsDownloadFailure: error => createAction(types.getMemberCoursesAnalyticsDownloadFailure, error),

  getMemberCourseAnalytics: data => createAction(types.getMemberCourseAnalytics, data),
  getMemberCourseAnalyticsSuccess: data => createAction(types.getMemberCourseAnalyticsSuccess, data),
  getMemberCourseAnalyticsFailure: error => createAction(types.getMemberCourseAnalyticsFailure, error),

  getMemberCourseAnalyticsDownloadCsv: data => createAction(types.getMemberCourseAnalyticsDownloadCsv, data),
  getMemberCourseAnalyticsDownloadCsvSuccess: data => createAction(types.getMemberCourseAnalyticsDownloadCsvSuccess, data),
  getMemberCourseAnalyticsDownloadCsvFailure: error => createAction(types.getMemberCourseAnalyticsDownloadCsvFailure, error),

  getMemberCourseAnalyticsDownloadPdf: data => createAction(types.getMemberCourseAnalyticsDownloadPdf, data),
  getMemberCourseAnalyticsDownloadPdfSuccess: data => createAction(types.getMemberCourseAnalyticsDownloadPdfSuccess, data),
  getMemberCourseAnalyticsDownloadPdfFailure: error => createAction(types.getMemberCourseAnalyticsDownloadPdfFailure, error),

  getLabelsAnalytics: data => createAction(types.getLabelsAnalytics, data),
  getLabelsAnalyticsSuccess: data => createAction(types.getLabelsAnalyticsSuccess, data),
  getLabelsAnalyticsFailure: error => createAction(types.getLabelsAnalyticsFailure, error),

  getLabelCoursesAnalytics: data => createAction(types.getLabelCoursesAnalytics, data),
  getLabelCoursesAnalyticsSuccess: data => createAction(types.getLabelCoursesAnalyticsSuccess, data),
  getLabelCoursesAnalyticsFailure: error => createAction(types.getLabelCoursesAnalyticsFailure, error),
};
