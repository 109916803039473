import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled/macro';
import Grid from 'components/Grid';
import { Field } from 'react-final-form';
import Form from 'components/FinalForm/Form';
import FormField from 'components/FinalForm/Fields/FormField';
import Button from 'components/Button';
import Collapse from '@mui/material/Collapse';
import { useDispatch, useSelector } from 'react-redux';
import { workspaceActions } from 'redux/workspace';
import { authSelectors } from 'redux/auth';
import validator, { isRequired } from 'validators';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useFeature from 'hooks/useFeature';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import IconText from 'components/IconText';
import useMediaQuery from '@mui/material/useMediaQuery';
import PulseEmojiField from './PulseEmojiField';

const Wrapper = styled('div')`
  margin: 2.5rem 0;
`;

const SmallText = styled(IconText)`
  color: #666666;
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  white-space: initial;
  text-align: center;
`;

const BigText = styled('div')`
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
`;

const FormFieldWrapper = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const FormFieldWrapperInner = styled('div')`
  max-width: 500px;
  width: 100%;
`;

const StyledFormField = styled(FormField)`
  textarea:not(:focus) {
    &::-webkit-input-placeholder { opacity: 1 }
    &::-moz-placeholder { opacity: 1 }
    &:-ms-input-placeholder { opacity: 1 }
    &::-ms-input-placeholder { opacity: 1 }
  }
`;

const PulseForm = ({ onFinish, ...rest }) => {

  const dispatch = useDispatch();

  const thanksRef = useRef();

  const pulseFeature = useFeature('pulse');

  const { t } = useTranslation(['component']);

  const workspace = useSelector(authSelectors.getWorkspace);

  const [visible, setVisible] = useState(false);

  const smallScreen = useMediaQuery('(max-width: 400px)');

  // Display fast if required. Disappear slow
  // Disappearance is likely due to answering... So fade away
  useEffect(() => {
    if (workspace.pulse) {
      setVisible(true);
      return;
    }

    if (!workspace) {
      return;
    }

    if (visible) {
      const fn = () => {
        setVisible(false);
        onFinish();
      };
      const timeout = setTimeout(fn, 2000);
      return () => clearTimeout(timeout);
    }

    onFinish();
  }, [onFinish, visible, workspace]);

  if (!pulseFeature.enabled) {
    return null;
  }

  const onSubmit = values => {
    dispatch(workspaceActions.sendPulseFeedback(values));
    if (thanksRef.current) {
      thanksRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  };

  const validate = validator({
    value: [isRequired],
  });

  return (
    <Collapse in={visible} unmountOnExit {...rest}>
      <Form onSubmit={onSubmit} validate={validate}>
        {({ handleSubmit, values, submitting, submitSucceeded }) => (
          <Wrapper>
            <Collapse in={submitSucceeded} ref={thanksRef}>
              <BigText>{t('component:pulseForm.thanksForResponse')}</BigText>
            </Collapse>
            <Collapse component="form" in={!submitSucceeded} onSubmit={handleSubmit}>
              <Grid container direction="column" wrap="nowrap" spacing={3}>

                <Grid item>
                  <SmallText>{t('component:pulseForm.introSubText', { workspace: workspace.name })}</SmallText>
                  <BigText>{t('component:pulseForm.mainQuestion')}</BigText>
                </Grid>

                <Grid item>
                  <Field name="value" component={PulseEmojiField} style={{ margin: '1.5rem 0' }} />
                </Grid>

                <Collapse in={!!values.value} component={Grid} item>
                  <FormFieldWrapper>
                    <FormFieldWrapperInner>
                      <Grid container spacing={1} alignItems="center" justifyContent="center">
                        <Grid item xs={smallScreen ? 12 : true}>
                          <StyledFormField
                            name="comment"
                            variant="outlined"
                            margin="none"
                            size="medium"
                            multiline
                            maxRows={7}
                            minRows={1}
                            placeholder={t('component:pulseForm.feedbackPlaceholder')}
                          />
                        </Grid>
                        <Grid item>
                          <Button type="submit" size="large" disabled={submitting}>{t('component:pulseForm.submit')}</Button>
                        </Grid>
                      </Grid>
                    </FormFieldWrapperInner>
                  </FormFieldWrapper>
                </Collapse>
                <Grid item>
                  <SmallText startIcon={<LockOutlinedIcon fontSize="inherit" color="inherit" />}>
                    {t('component:pulseForm.anonymousDisclaimer')}
                  </SmallText>
                </Grid>

              </Grid>
            </Collapse>
          </Wrapper>

        )}

      </Form>
    </Collapse>
  );
};

PulseForm.propTypes = {
  onFinish: PropTypes.func,
};

PulseForm.defaultProps = {
  onFinish: () => {},
};

export default PulseForm;
