import React, { useContext } from 'react';
import Drawer from 'containers/Side/Drawer/Drawer';
import DrawerTitle from 'containers/Side/Drawer/DrawerTitle';
import { useHistory, useLocation } from 'react-router';
import { authSelectors } from 'redux/auth';
import { useSelector } from 'react-redux';
import useFeature from 'hooks/useFeature';
import MemberEvents from './MemberEvents/MemberEvents';
import { getCloseButtonLink } from './MemberEvents/memberEventsService';
import ContactDirectory from './ContactDirectory/ContactDirectory';
import SideProvider from './SideProvider';
import ViewContext from './ViewContext';
import ManageContacts from './ContactDirectory/ManageContacts';

const Side = () => {

  const history = useHistory();
  const location = useLocation();

  const PossibleContent = {};

  const { side } = useContext(ViewContext);

  if (useFeature('birthdaysAndJobAnniversaries').enabled) {
    PossibleContent.birthdays = MemberEvents;
    PossibleContent.jobAnniversaries = MemberEvents;
  }

  if (useFeature('peopleDirectory').enabled) {
    PossibleContent.directory = ContactDirectory;
    PossibleContent.contacts = ManageContacts;
  }

  const Content = PossibleContent[side];

  // All functionality in Side requres workspaceId
  // When not present then defer rendering anything at all.
  const workspaceId = useSelector(authSelectors.getWorkspaceId);

  if (!workspaceId || !Content) {
    return null;
  }

  return (
    <SideProvider>
      <Drawer open={!!side} anchor="right" onClose={() => history.replace(getCloseButtonLink(location))}>
        <DrawerTitle />
        <Content />
      </Drawer>
    </SideProvider>
  );
};

const SideWithProvider = () => (
  <SideProvider>
    <Side />
  </SideProvider>
);

export default SideWithProvider;
