import React, { useEffect, useCallback } from 'react';
import { useField } from 'react-final-form';
import useFileInput from 'hooks/useFileInput';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { useTranslation } from 'react-i18next';
import useCapabilities from '../../useCapabilities';

const useMediaButtonProps = args => {

  const { t } = useTranslation(['component']);

  const { media } = useCapabilities();

  const { input: { value, onChange } } = useField('media');

  const reset = useCallback(() => {
    onChange(undefined);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!media) reset();
  }, [media, reset]);

  const onFileChange = files => {
    onChange([...value, ...files]);
  };

  const { open } = useFileInput({
    onChange: onFileChange,
    accept: 'image/png,image/jpeg,.jpg,video/*',
    multiple: true,
  });

  return {
    startIcon: <CameraAltOutlinedIcon />,
    onClick: open,
    children: t('postForm.mediaAddButton'),
    ...args,
  };
};

export default useMediaButtonProps;
