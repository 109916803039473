import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postActions, postSelectors } from 'redux/post';
import { translationSelectors } from 'redux/translation';
import { PostHeader, PostMedia, PostBody, PostTargets, PostTargetUsersCount, PostWrapper, PostActivity, PostContext } from 'components/Post';
import PropTypes from 'prop-types';
import { sanitizePostBody } from 'services/sanitizationService';
import linkifyHtml from 'linkify-html';
import history from 'providers/history';
import PostAttachments from 'components/Post/PostAttachments';
import PostPreHeader from 'components/Post/PostPreHeader';
import { isMobile } from 'helpers';
import PostHeaderDraft from 'components/Post/PostHeaderDraft';
import PostHeaderScheduled from 'components/Post/PostHeaderScheduled';
import PostQuiz from './PostQuiz';
import PostPoll from './PostPoll';
import PostFooter from './PostFooter';
import PostInfo from './PostInfo';
import PostOpengraph from './PostOpengraph';

const linkifyOptions = {
  target: {
    url: '_blank',
  },
  events: {
    onClick: e => e.stopPropagation(),
  },
  attributes: {
    rel: 'noopener noreferrer nofollow',
  },
};

const sanitizeHtmlOptions = {
  // Counter css pre-wrap
  textFilter: text => text.replace(/(?:\r\n|\r|\n)/g, ''),
};

const encodeHtml = (str) => (str || '').replace(/[\u00A0-\u9999<>&]/g, (i) => `&#${i.charCodeAt(0)};`);

const Post = ({ isCompact, postId, feedName, ...rest }) => {

  const dispatch = useDispatch();

  const [translated, _setTranslated] = useState(false);

  const fetchTranslation = () => dispatch(postActions.getTranslations({ postId }));

  const setTranslated = value => {
    _setTranslated(value);
    _setTranslated(current => {
      if (current) {
        fetchTranslation();
      }

      return current;
    });
  };

  const post = useSelector(state => postSelectors.getPost(state, postId));

  const getTranslation = useSelector(translationSelectors.getTranslationSelector);

  const bodyProps = useMemo(() => {
    if (!post || post.deleted) {
      return {};
    }

    let content;
    if (translated) {
      const body = getTranslation('post', postId, 'body', post.body);
      const bodyHtml = getTranslation('post', postId, 'body_html', null);
      content = bodyHtml
        ? sanitizePostBody(bodyHtml, sanitizeHtmlOptions)
        : sanitizePostBody(linkifyHtml(body || '', linkifyOptions));
    } else {
      content = post && post.bodyHtml
        ? sanitizePostBody(post.bodyHtml, sanitizeHtmlOptions)
        : sanitizePostBody(linkifyHtml(encodeHtml(post.body || ''), linkifyOptions));
    }

    return {
      html: content,
    };
  }, [getTranslation, post, postId, translated]);

  if (!post || post.deleted) {
    return null;
  }

  const { user, media, poll, quiz, attachments, activity, targets, targetUsersCount, permissions, opengraph, isDraft, isFollowing, scheduledAt, postedAt } = post;

  const headerProps = {
    memberId: user.memberId,
    firstName: user.firstName,
    lastName: user.lastName,
    avatar: user.avatar,
    info: <PostInfo postId={postId} />,
  };

  const linkToPost = isCompact ? {
    onClick: e => e.target.tagName !== 'A' ? history.push(`/post/${postId}`) : null,
  } : {};

  const isImportant = post.important;
  const isPinned = !!post.pinned;
  const isPinnable = !!post?.feedPinnable?.[feedName]; // Assume without feedname it's single post view

  const hasMenu = [
    post.permissions.pin && isPinnable,
    post.permissions.important,
    post.permissions.follow,
    post.permissions.poll && post.permissions.poll.viewVoters && !isMobile,
    post.permissions.update,
    post.permissions.delete,
    post.isDraft,
  ].reduce((acc, v) => acc || v);

  const context = { isCompact, isImportant, isPinned, isPinnable, isFollowing, isDraft, hasMenu, translated, setTranslated };

  let preheader = null;

  if (isDraft) {
    preheader = <PostHeaderDraft />;
  } else if (!postedAt && scheduledAt) {
    preheader = <PostHeaderScheduled scheduledAt={scheduledAt} />;
  } else if (targets && targets.length > 0) {
    preheader = <PostTargets targets={targets} />;
  } else if (targetUsersCount && targetUsersCount > 0) {
    preheader = <PostTargetUsersCount count={targetUsersCount} />;
  }

  return (
    <PostContext.Provider value={context}>
      <PostWrapper postId={postId} {...rest}>
        {(preheader || (isPinnable && isPinned)) && <PostPreHeader>{preheader}</PostPreHeader>}
        {post.show?.header && <PostHeader {...headerProps} />}
        {activity && <PostActivity activity={activity} {...linkToPost} />}
        <PostBody {...linkToPost} {...bodyProps} />
        {poll && <PostPoll pollId={poll} viewVotes={!!permissions.poll?.viewVotes} viewVoters={!!permissions.poll?.viewVoters} />}
        {quiz && <PostQuiz quizId={quiz} />}
        {attachments && <PostAttachments attachments={attachments} />}
        {media?.length > 0 && <PostMedia media={post.media} />}
        {opengraph && !media?.length && <PostOpengraph {...opengraph} />}
        <PostFooter postId={postId} />
      </PostWrapper>
    </PostContext.Provider>
  );
};

Post.propTypes = {
  postId: PropTypes.any,
  isCompact: PropTypes.bool,
  feedName: PropTypes.string,
};

Post.defaultProps = {
  isCompact: false,
};

export default Post;
