import React from 'react';
import styled from '@emotion/styled/macro';
import { Global, css } from '@emotion/react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import config from 'config';
import { genericConfigValidElements } from 'services/sanitizationService';
import { useTranslation } from 'react-i18next';
import { HtmlContentBase, htmlContentCss } from 'components/HtmlContent';
import aiRequest from 'services/aiRequest';
import useFeature from 'hooks/useFeature';

const InputWrapper = styled('div')`
  width: 100%;
`;

const GlobalStyles = () => (
  <Global styles={css`
    .tox {
      && .tox-statusbar,
      && .tox-toolbar__group {
        border-color: #f0f0f0 !important;
      }

      && .tox-toolbar__primary {
        /* border-bottom: 1px solid #f0f0f0; */
        background: none !important;
      }

      && .tox-statusbar a {
        color: #888;
      }

      && .tox-statusbar {
        border-top: 0;
      }

      &.tox-silver-sink {
        position: fixed !important;
      }

      &.tox-tinymce {
        border: none;
      }

      & .tox-tbtn {
        cursor: pointer;
      }

      /* Our custom button */
      & .tox-toolbar__primary .tox-toolbar__group:last-child .tox-tbtn:last-child,
      & .tox-toolbar__primary .tox-toolbar__group:last-child .tox-tbtn:last-child * {
        cursor: pointer;
      }

      && .tox-tbtn--enabled,
      && .tox-tbtn:hover,
      && .tox-tbtn:active,
      && .tox-tbtn:focus {
        background-color: rgba(0, 0, 0, 0.07);
      }

      && .tox-toolbar__overflow {
        min-width: 40px;
      }

      && .tox-toolbar__group:last-child .tox-tbtn:last-child {
        margin-left: auto;
      }

      &&:not(.tox-tinymce-inline) .tox-editor-header {
        padding: 0;
      }
    }
  `}
  />
);

const styledTextFieldConfig = {
  shouldForwardProp: prop => !['toggleRich'].includes(prop),
};

const StyledTextField = styled(TextField, styledTextFieldConfig)`
  &&& > div {
    padding: 0.4rem 2px 2px;
  }
`;

// HACK: Link + Emoticons dialogs are not positioned correctly.
// Disable those buttons. /Edge\/|Trident\/|MSIE /
const isIE = window && window.navigator && window.navigator.userAgent && /Trident\/|MSIE /.test(window.navigator.userAgent);

const editorDialogFixer = () => {
  // If we're oparating inside a dialog,
  // then rich editor's menus / dialogs / popups would not be visible.
  // Move the rich editor dialog wrapper inside the Material-UI Wrapper
  const dialogContainer = document.getElementsByClassName('MuiDialog-container')[0];
  if (dialogContainer) {
    const fragment = document.createDocumentFragment();
    fragment.appendChild(document.getElementsByClassName('tox-tinymce-aux')[0]);
    dialogContainer.appendChild(fragment);
  }
};

const RichEditorInput = React.forwardRef(({ placeholder, className, init: initOverrides, extraPlugins, extraToolbar, onInput, onContentEvents, openai, ...rest }, ref) => {
  const { t } = useTranslation(['component']);

  const openAI = useFeature('tinymceOpenai');

  const morePlugins = openAI.enabled && openai ? ['ai'] : [];

  const maybeButton = (button) => (extraToolbar || []).includes(button) ? ` ${button} ` : '';

  const init = {
    menubar: false, // Turn off the top menu, above the toolbar, "File", "Edit", "View" w/e

    placeholder,

    plugins: ['link', 'autolink', 'lists', 'emoticons', 'autoresize', 'fullscreen', ...(extraPlugins || []), ...morePlugins],
    toolbar: [
      'blocks aidialog aishortcuts',
      `bold italic underline ${maybeButton('backcolor')}`,
      'numlist bullist blockquote link',
      'emoticons image media',
      'undo redo',
      'outdent indent disableRich',
    ].join(' | '),

    toolbar_mode: 'floating',

    elementpath: false, // Remove elementpath from the statusbar
    statusbar: false,
    branding: false,

    keep_styles: false,

    // skin: false, // Don't load external files
    // content_css: false, // Don't load external files

    content_style: htmlContentCss.styles,
    valid_elements: genericConfigValidElements,

    browser_spellcheck: true,

    entity_encoding: 'raw',

    block_formats: [
      `${t('richEditorField.headingOne', 'Heading 1')}=h2`,
      `${t('richEditorField.headingTwo', 'Heading 2')}=h3`,
      `${t('richEditorField.paragraph', 'Paragraph')}=p`,
    ].join(';'),

    editimage_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',

    formats: {
      // Changes the default format for the underline button to produce a span with a class and not merge that underline into parent spans
      underline: { inline: 'u' },
      strikethrough: { inline: 's' },
    },

    // Link plugin options
    link_assume_external_targets: 'http',
    default_link_target: '_blank',
    link_title: false,
    link_target_list: false,

    // Autoresize plugin options
    max_height: 300,

    convert_urls: false,
    relative_urls: false,
    remove_script_host: false,

    quickbars_selection_toolbar: false,
    quickbars_insert_toolbar: false,

    color_map_background: [
      '#BFEDD2', 'Light Green',
      '#FBEEB8', 'Light Yellow',
      '#F8CAC6', 'Light Red',
      '#ECCAFA', 'Light Purple',
      '#C2E0F4', 'Light Blue',
      '#2DC26B', 'Green',
      '#F1C40F', 'Yellow',
      '#E03E2D', 'Red',
      '#ECF0F1', 'Light Gray',
    ],
    color_default_background: 'rgb(251, 238, 184)',
    custom_colors: true,

    // Textpattern plugin options
    text_patterns: [
      { start: '1. ', cmd: 'InsertOrderedList' },
      { start: '1) ', cmd: 'InsertOrderedList' },
      { start: '* ', cmd: 'InsertUnorderedList' },
      { start: '- ', cmd: 'InsertUnorderedList' },
    ],

    ...initOverrides,

    ai_request: aiRequest,

    file_picker_callback: (cb) => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');

      input.addEventListener('change', (e) => {
        const file = e.target.files[0];

        const reader = new FileReader();
        reader.addEventListener('load', () => {
          /*
            Note: Now we need to register the blob in TinyMCEs image blob
            registry. In the next release this part hopefully won't be
            necessary, as we are looking to handle it internally.
          */
          const id = `blobid${(new Date()).getTime()}`;
          const { blobCache } = window.tinymce.activeEditor.editorUpload;
          const base64 = reader.result.split(',')[1];
          const blobInfo = blobCache.create(id, file, base64);
          blobCache.add(blobInfo);

          /* call the callback and populate the Title field with the file name */
          cb(blobInfo.blobUri(), { title: file.name });
        });
        reader.readAsDataURL(file);
      });

      input.click();
    },

    setup: editor => {
      editor.on('init', editorDialogFixer);

      if (onInput) {
        editor.on('input', onInput);
      }

      if (onContentEvents) {
        editor.on('paste cut redo undo', (e) => onContentEvents(e, editor));
        editor.on('ExecCommand', (e) => {
          if (e.command === 'mceInsertLink' || e.command === 'unlink') {
            onContentEvents(e, editor);
          }
        });
      }

      if (initOverrides?.setup) {
        initOverrides.setup(editor);
      }
    },
  };

  // HACK: Link + Emoticons dialogs are not positioned correctly.
  // Disable those buttons. /Edge\/|Trident\/|MSIE /
  init.toolbar = isIE ? init.toolbar.replace(' link', '').replace(' emoticons', '') : init.toolbar;

  return (
    <InputWrapper className={className} ref={ref}>
      <GlobalStyles />
      <HtmlContentBase>
        <Editor apiKey={config.tinymce.key} init={init} {...rest} />
      </HtmlContentBase>
    </InputWrapper>
  );
});

RichEditorInput.propTypes = {
  toggleRich: PropTypes.func,
  placeholder: PropTypes.string,

  init: PropTypes.object,
  extraPlugins: PropTypes.array,
  extraToolbar: PropTypes.array,

  openai: PropTypes.bool,

  onInput: PropTypes.func,
  onContentEvents: PropTypes.func,
};

const RichEditor = ({ onChange, onBlur, onFocus, onPaste, onKeyDown, onKeyUp, onInput, onContentEvents, value, name, error, helperText, extraPlugins, extraToolbar, init, openai, ...rest }) => (
  <StyledTextField
    multiline
    variant="outlined"
    FormHelperTextProps={{
      variant: 'standard',
    }}
    InputProps={{
      inputComponent: RichEditorInput,
      notched: true,
      inputProps: {
        textareaName: name,
        onEditorChange: onChange,
        value,
        onBlur,
        onFocus,
        onPaste,
        onKeyDown,
        onKeyUp,
        onInput,
        onContentEvents,

        openai,
        extraPlugins,
        extraToolbar,
        init,
      },
    }}
    error={error}
    helperText={helperText}
    {...rest}
  />
);

RichEditor.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onPaste: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onInput: PropTypes.func,
  onContentEvents: PropTypes.func,

  error: PropTypes.bool,
  helperText: PropTypes.string,

  toggleRich: PropTypes.func,

  openai: PropTypes.bool,
  init: PropTypes.object,
  extraPlugins: PropTypes.array,
  extraToolbar: PropTypes.array,
};

export default RichEditor;
