import React, { useMemo } from 'react';
import Avatar from 'components/Avatar';
import PropTypes from 'prop-types';
import uniqBy from 'lodash/uniqBy';
import AvatarGroup from '@mui/material/AvatarGroup';
import styled from '@emotion/styled';

const StyledAvatarGroup = styled(AvatarGroup)`
  .MuiAvatar-root {
    border: 1px solid #fff;
  }
`;

const AvatarRow = ({ users, max, size = 20, total }) => {

  const head = useMemo(() => uniqBy(users, (user) => user.id).slice(0, max), [max, users]);

  const title = useMemo(() => {
    const preview = users
      .map(({ name, firstName, lastName }) => name ? [name] : [firstName, lastName])
      .map(nameParts => nameParts.filter(v => !!v).join(' '))
      .filter(v => !!v)
      .slice(0, 15);

    const tailCount = total - preview.length;

    return tailCount > 0
      ? `${preview.join(', ')} +${tailCount}`
      : preview.join(', ');
  }, [total, users]);

  return (
    <StyledAvatarGroup
      max={max || 3}
      spacing={4}
      total={total}
      title={title}
      slotProps={{ additionalAvatar: { size, as: Avatar } }}
    >
      {head.map(user => user && (
        <Avatar
          src={user.avatar?.src}
          srcSet={user.avatar?.srcSet}
          size={size}
          title={null}
          name={user.name}
          firstName={user.firstName}
          lastName={user.lastName}
          key={user.id}
        />
      ))}
    </StyledAvatarGroup>
  );
};

AvatarRow.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.any,
    avatar: PropTypes.shape({
      src: PropTypes.string,
      srcSet: PropTypes.string,
    }),
    name: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  })),
  max: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
};

AvatarRow.defaultProps = {
  max: 3,
};

export default AvatarRow;
