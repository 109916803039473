import React from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import Avatar from 'components/Avatar';
import CardContent from 'components/CardContent';
import { useTheme } from '@emotion/react';
import ProgressiveBackgroundImage from './ProgressiveBackgroundImage';
import MaxWidthContainer from './MaxWidthContainer';

const Wrapper = styled('div')`
  background: black;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  ${({ coverSrc }) => coverSrc && `background-image: url(${coverSrc});`}

  background-size: cover, cover;
  background-position: center, center;
  background-repeat: no-repeat, no-repeat;
  background-image: ${({ coverSrc }) => coverSrc
    ? `linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 71%, rgba(0,0,0,0.53) 100%), url(${coverSrc})`
    : 'linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 71%, rgba(0,0,0,0.53) 100%)'};

`;

const InnerWrapper = styled(CardContent)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  height: 250px;
  width: 100%;

  margin: 0 auto;
  padding-bottom: 1rem;
`;

const Title = styled('h2')`
  font-size: 1.6rem;
  color: #fff;
  font-weight: 700;
  margin: 0;

  text-shadow: 0 0 3px rgba(0, 0, 0, .8);
`;

const AvatarWrapper = styled('div')`
  padding: 8px;
  background: #fff;
  border-radius: 50%;
  position: relative;
  transform: translateY(50%);
  margin-left: auto;
  margin-bottom: -1rem; /* 1rem for header padding */
`;

export const AvatarProtector = styled('div')`
  width: 116px;
  height: 58px;
  float: right;
`;

const Header = ({ disableContainerGutters, name, avatar, cover, ...rest }) => {

  const theme = useTheme();

  return (
    <ProgressiveBackgroundImage src={cover?.src} srcSet={cover?.srcSet} sizes={theme.feedImageSrcSetSizes}>
      {({ src }) => (
        <Wrapper coverSrc={src} {...rest}>
          <MaxWidthContainer disableGutters={disableContainerGutters}>
            <InnerWrapper>
              {name && <Title>{name}</Title>}
              {avatar && <AvatarWrapper><Avatar size={100} name={name} src={avatar?.src} srcSet={avatar?.srcSet} fallbackIcon={avatar?.fallbackIcon} /></AvatarWrapper>}
            </InnerWrapper>
          </MaxWidthContainer>
        </Wrapper>
      )}
    </ProgressiveBackgroundImage>
  );
};

Header.propTypes = {
  disableContainerGutters: PropTypes.bool,

  name: PropTypes.string,
  avatar: PropTypes.oneOfType([
    PropTypes.shape({
      src: PropTypes.string,
      srcSet: PropTypes.string,
    }),
    PropTypes.bool,
  ]),
  cover: PropTypes.shape({
    src: PropTypes.string,
    srcSet: PropTypes.string,
  }),
};

Header.defaultProps = {
  disableContainerGutters: true,
};

export default Header;
