import React from 'react';
import styled from '@emotion/styled/macro';
import { css, Global } from '@emotion/react';
import PropTypes from 'propTypes';
import FullScreenHeader from './FullScreenHeader';

const GlobalStyle = () => {
  return (
    <Global styles={css`
      body {
        background-color: #fff;
      }
    `}
    />
  );
};

const RootContainer = styled('div')`
  width: 100%;
  height: 100%;
  padding-top: 4rem;

  max-width: ${({ theme }) => theme.maxWidth};
  margin: 0 auto;
  position: relative;
`;

const MobileFullScreenLayout = ({ children, backUrl, title, rightComponent }) => {
  return (
    <>
      <GlobalStyle />
      <RootContainer>
        <FullScreenHeader
          backUrl={backUrl}
          title={title}
          rightComponent={rightComponent}
        />
        {children}
      </RootContainer>
    </>
  );
};

MobileFullScreenLayout.propTypes = {
  backUrl: PropTypes.string,
  title: PropTypes.string,
  rightComponent: PropTypes.component,
};

MobileFullScreenLayout.defaultProps = {
  backUrl: '#',
  title: '',
  rightComponent: <></>,
};

export default MobileFullScreenLayout;
