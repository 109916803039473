import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { css, useTheme } from '@emotion/react';
import Box from '@mui/material/Box';

const whiteTheme = () => ({
  baseBackground: 'rgba(255, 255, 255, 0.4)',
  progressBackground: '#fff',
  progressColor: '#333',
});

const companyTheme = theme => ({
  baseBackground: theme.transparentColor(0.8),
  progressBackground: theme.palette.primary.main,
  progressColor: theme.palette.primary.contrastText,
});

const Base = styled(Box)`
  height: 40px;
  width: 100%;
  border-radius: 100px;
  overflow: hidden;

  background-color: ${({ styles }) => styles.baseBackground};

  &::before {
    content: "${({ progress }) => progress < 25 ? Math.floor(progress) : progress}%";
    padding-right: ${({ progress }) => progress < 15 ? 0 : '1rem'};
    justify-content: ${({ progress }) => progress < 15 ? 'center' : 'flex-end'};

    display: flex;
    align-items: center;

    min-width: 40px;
    width: calc(${({ progress }) => progress}%);
    height: 100%;
    max-width: 100%;
    border-radius: 100px;
    background-color: ${({ styles }) => styles.progressBackground};
    color: ${({ styles }) => styles.progressColor};
    font-weight: 600;

    transition: width .8s ease;
  }

  ${({ disabled }) => disabled && css`
    background-color: #eee;
    &::before {
      background-color: #ccc;
      color: #fff;
    }
  `};
`;

const ProgressBar = ({ children, variant, ...rest }) => {

  const theme = useTheme();

  const getStyles = {
    white: whiteTheme,
    company: companyTheme,
  }[variant];

  const styles = getStyles(theme) || companyTheme(theme);

  return <Base styles={styles} {...rest} />;
};

ProgressBar.propTypes = {
  progress: PropTypes.number,
  variant: PropTypes.oneOf(['company', 'white']),
  disabled: PropTypes.bool,
};

ProgressBar.defaultProps = {
  progress: 0,
  variant: 'company',
};

export default ProgressBar;
