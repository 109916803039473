import { all, call, debounce, put, select, takeEvery, takeLatest, takeLeading, take, race } from 'redux-saga/effects';

import { api, apiCall } from 'redux/helpers/api';
import { authSelectors } from 'redux/auth';
import { snackbarActions } from 'redux/snackbar';
import { combineSortAndDirection } from 'services/requestService';
import actions from './actions';
import types from './types';
import { workspaceSelectors } from '.';

function* onGetWorkspaceChannels() {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, response } = yield apiCall(api.workspace.channels, { workspaceId });

  if (ok) {
    yield put(actions.getWorkspaceChannelsSuccess(response));
  }
}

function* onGetWorkspaceGroups({ payload = {} }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, response, error } = yield apiCall(api.workspace.groups, { workspaceId, ...payload });

  if (ok) {
    yield put(actions.getGroupsSuccess(response));
  } else {
    yield put(actions.getGroupsFailure(error));
  }
}

function* onGetMembers({ payload: { sort, sortDirection, ...rest } }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, error, response } = yield apiCall(api.workspace.member.index, {
    ...rest,
    ...(sort && { sort: combineSortAndDirection(sort, sortDirection) }),
    workspaceId,
  });

  if (ok) {
    yield put(actions.getMembersSuccess(response));
  } else {
    yield put(actions.getMembersFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onExportMembers({ payload: { sort, sortDirection, ...rest } }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, error, response } = yield apiCall(api.workspace.member.export, {
    ...rest,
    ...(sort && { sort: combineSortAndDirection(sort, sortDirection) }),
    workspaceId,
  });

  if (ok) {
    yield put(actions.exportMembersSuccess(response));
  } else {
    yield put(actions.exportMembersFailure(error));
  }
}

function* onGetMember({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, error, response } = yield apiCall(api.workspace.member.show, { workspaceId, ...payload });

  if (ok) {
    yield put(actions.getMemberSuccess(response));
  } else {
    yield put(actions.getMemberFailure(error));
  }
}

function* onAddMember({ payload, config }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, error, response } = yield apiCall(api.workspace.member.store, { workspaceId, ...payload }, config);

  if (ok) {
    yield put(actions.addMemberSuccess(response));
    yield put(actions.getLabels());
    yield put(snackbarActions.createSuccess('workspace.member.added'));
  } else {
    yield put(actions.addMemberFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onUpdateMember({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, error, response } = yield apiCall(api.workspace.member.update, { workspaceId, ...payload });


  if (ok) {
    yield put(actions.updateMemberSuccess(response));
    yield put(actions.getLabels());
    yield put(snackbarActions.createSuccess('workspace.member.updated'));
  } else {
    yield put(actions.updateMemberFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onReinviteMember({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, error, response } = yield apiCall(api.workspace.member.reinvite, { workspaceId, ...payload });

  if (ok) {
    yield put(actions.reinviteMemberSuccess(response));
    yield put(snackbarActions.createSuccess('workspace.member.reinvited'));
  } else {
    yield put(actions.reinviteMemberFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onDeleteMember({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, error, response } = yield apiCall(api.workspace.member.delete, { workspaceId, ...payload });

  if (ok) {
    yield put(actions.deleteMemberSuccess({ ...response, memberId: payload.memberId }));
    yield put(snackbarActions.createSuccess('workspace.member.deleted'));
  } else {
    yield put(actions.deleteMemberFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onMemberMassAction({ payload, config }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, error, response } = yield apiCall(api.workspace.member.massAction, { workspaceId, ...payload }, config);

  const successResponse = { ...payload, ...response };

  // Quick solution to get all necessary IDs in case some new labels were created
  if (ok && (payload.action === 'add_label' || payload.action === 'add_analytical_label')) {
    yield onGetLabels();
    const labelSelector = payload.action === 'add_label' ? workspaceSelectors.getLabels : workspaceSelectors.getAnalyticalLabels;
    const labels = yield select(labelSelector);
    successResponse.data.labels = successResponse.data.labels.map(labelName => labels.find(({ name }) => name === labelName));
  }

  if (ok) {
    yield put(actions.memberMassActionSuccess(successResponse));
    yield put(snackbarActions.createSuccess(`workspace.member.massaction.${payload.action}`));
  } else {
    yield put(actions.memberMassActionFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetUser() {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, error, response } = yield apiCall(api.workspace.getUser, { workspaceId });

  if (ok) {
    yield put(actions.getUserSuccess(response));
  } else {
    yield put(actions.getUserFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onUpdateUser({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, error, response } = yield apiCall(api.workspace.updateUser, { workspaceId, ...payload });

  if (ok) {
    yield put(actions.updateUserSuccess(response));
    yield put(snackbarActions.createSuccess('user.profileUpdated'));
  } else {
    yield put(actions.updateUserFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetLabels() {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const [q1, q2] = yield all([
    call(apiCall, api.workspace.labels, { workspaceId, type: 'DEFAULT' }),
    call(apiCall, api.workspace.labels, { workspaceId, type: 'ANALYTICAL' }),
  ]);

  function* handle(type, { ok, error, response }) {
    if (ok) {
      yield put(actions.getLabelsSuccess({ ...response, type }));
    } else {
      yield put(actions.getLabelsFailure(error));
    }
  }

  yield handle('DEFAULT', q1);
  yield handle('ANALYTICAL', q2);
}


function* onGetInvitation() {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, error, response } = yield apiCall(api.workspace.invitation, { workspaceId });

  if (ok) {
    yield put(actions.getInvitationSuccess(response));
  } else {
    yield put(actions.getInvitationFailure(error));
  }
}

function* onRegenerateInvitation() {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, error, response } = yield apiCall(api.workspace.regenerateInvitation, { workspaceId });

  if (ok) {
    yield put(actions.regenerateInvitationSuccess(response));
    yield put(snackbarActions.createSuccess('workspace.invitationRegenerated'));
  } else {
    yield put(actions.regenerateInvitationFailure(error));
  }
}

function* onGetWorkspaceSettings() {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, error, response } = yield apiCall(api.workspace.settings, { workspaceId });

  if (ok) {
    yield put(actions.getWorkspaceSettingsSuccess(response));
  } else {
    yield put(actions.getWorkspaceSettingsFailure(error));
  }
}

function* onGetWorkspaceProfile() {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, error, response } = yield apiCall(api.workspace.profile, { workspaceId });

  if (ok) {
    yield put(actions.getWorkspaceProfileSuccess(response));
  } else {
    yield put(actions.getWorkspaceProfileFailure(error));
  }
}

function* onUpdateWorkspaceSettings({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, error, response } = yield apiCall(api.workspace.updateSettings, { ...payload, workspaceId });

  if (ok) {
    yield put(actions.updateWorkspaceSettingsSuccess(response));
    yield put(snackbarActions.createSuccess('workspace.updated'));
  } else {
    yield put(actions.updateWorkspaceSettingsFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onUpdateWorkspaceProfile({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, error, response } = yield apiCall(api.workspace.updateProfile, { ...payload, workspaceId });

  if (ok) {
    yield put(actions.updateWorkspaceProfileSuccess(response));
    yield put(snackbarActions.createSuccess('workspace.updated'));
  } else {
    yield put(actions.updateWorkspaceProfileFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetWorkspacePulseSettings() {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, error, response } = yield apiCall(api.workspace.pulseSettings, { workspaceId });

  if (ok) {
    yield put(actions.getWorkspacePulseSettingsSuccess(response));
  } else {
    yield put(actions.getWorkspacePulseSettingsFailure(error));
  }
}

function* onUpdateWorkspacePulseSettings({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, error, response } = yield apiCall(api.workspace.updatePulseSettings, { workspaceId, ...payload });

  if (ok) {
    yield put(snackbarActions.createSuccess('workspace.updated'));
    yield put(actions.updateWorkspacePulseSettingsSuccess(response));
  } else {
    yield put(snackbarActions.createFailure(error.message));
    yield put(actions.updateWorkspacePulseSettingsFailure(error));
  }
}


function* onSendPulseFeedback({ payload, config }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, error } = yield apiCall(api.workspace.sendPulseFeedback, { ...payload, workspaceId }, config);
  if (ok) {
    yield put(actions.sendPulseFeedbackSuccess(payload));
    yield put(snackbarActions.createSuccess('workspace.feedbackSent'));
  } else {
    yield put(actions.sendPulseFeedbackFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetChallenges({ payload, config }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, error, response } = yield apiCall(api.workspace.challenge.get, { workspaceId, ...payload }, config);

  if (ok) {
    yield put(actions.getChallengesSuccess({ ...response, active: payload.active }));
  } else {
    yield put(actions.getChallengesFailure(error));
  }
}

function* onGetChallengeTemplates({ config }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, error, response } = yield apiCall(api.workspace.challenge.templates, { workspaceId }, config);

  if (ok) {
    yield put(actions.getChallengeTemplatesSuccess(response));
  } else {
    yield put(actions.getChallengeTemplatesFailure(error));
  }
}

function* onEndChallenge({ payload: { challengeId }, config }) {
  const end = 1;
  const { ok, error, response } = yield apiCall(api.workspace.challenge.update, { challengeId, end }, config);

  if (ok) {
    yield put(actions.endChallengeSuccess({ challengeId, ...response }));
    yield put(snackbarActions.createSuccess('challenge.ended'));
    yield put(actions.getChallenges({ active: 0 }));
  } else {
    yield put(actions.endChallengeFailure(error));
  }
}

function* onStoreChallenge({ payload, config }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, error, response } = yield apiCall(api.workspace.challenge.store, { workspaceId, ...payload }, config);

  if (ok) {
    yield put(actions.storeChallengeSuccess(response));
    yield put(snackbarActions.createSuccess('challenge.created'));
    yield put(actions.getChallenges({ active: 1 }));
  } else {
    yield put(actions.storeChallengeFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onUpdateChallenge({ payload, config }) {
  const { ok, error, response } = yield apiCall(api.workspace.challenge.update, payload, config);

  if (ok) {
    yield put(actions.updateChallengeSuccess(response));
    yield put(snackbarActions.createSuccess('challenge.updated'));
    yield put(actions.getChallenges({ active: 1 }));
  } else {
    yield put(actions.updateChallengeFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onExportChallenge({ payload }) {
  const { ok, error } = yield apiCall(api.workspace.challenge.download, payload);

  if (ok) {
    yield put(actions.exportChallengeSuccess(payload));
  } else {
    yield put(actions.exportChallengeFailure(error));
  }
}

function* onGetAnalyticsOverview({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { period, labelId } = payload;
  const { ok, error, response } = yield apiCall(api.workspace.analytics.getOverview, { workspaceId, ...payload });
  if (ok) {
    yield put(actions.getAnalyticsOverviewSuccess({ period, data: response, labelId }));
  } else {
    yield put(actions.getAnalyticsOverviewFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetAnalyticsPulse({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { period, labelId } = payload;
  const { ok, error, response } = yield apiCall(api.workspace.analytics.getPulse, { workspaceId, ...payload });
  if (ok) {
    yield put(actions.getAnalyticsPulseSuccess({ period, data: response, labelId }));
  } else {
    yield put(actions.getAnalyticsPulseFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetAnalyticsFunnel({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { period, labelId } = payload;
  const { ok, error, response } = yield apiCall(api.workspace.analytics.getFunnel, { workspaceId, ...payload });
  if (ok) {
    yield put(actions.getAnalyticsFunnelSuccess({ period, data: response, labelId }));
  } else {
    yield put(actions.getAnalyticsFunnelFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetAnalyticsPulseExport({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { period, labelId } = payload;
  const { ok, error, response } = yield apiCall(api.workspace.analytics.getPulseExport, { workspaceId, ...payload });
  if (ok) {
    yield put(actions.getAnalyticsPulseExportSuccess({ period, data: response, labelId }));
  } else {
    yield put(actions.getAnalyticsPulseExportFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetAnalyticsPosts({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { period, labelId } = payload;
  const { ok, error, response } = yield apiCall(api.workspace.analytics.getPosts, { workspaceId, ...payload });
  if (ok) {
    yield put(actions.getAnalyticsPostsSuccess({ period, data: response, labelId }));
  } else {
    yield put(actions.getAnalyticsPostsFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetAnalyticsComments({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { period, labelId } = payload;
  const { ok, error, response } = yield apiCall(api.workspace.analytics.getComments, { workspaceId, ...payload });
  if (ok) {
    yield put(actions.getAnalyticsCommentsSuccess({ period, data: response, labelId }));
  } else {
    yield put(actions.getAnalyticsCommentsFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetAnalyticsLikes({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { period, labelId } = payload;
  const { ok, error, response } = yield apiCall(api.workspace.analytics.getLikes, { workspaceId, ...payload });
  if (ok) {
    yield put(actions.getAnalyticsLikesSuccess({ period, data: response, labelId }));
  } else {
    yield put(actions.getAnalyticsLikesFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetAnalyticsMembers({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { period, labelId } = payload;
  const { ok, error, response } = yield apiCall(api.workspace.analytics.getMembers, { workspaceId, ...payload });
  if (ok) {
    yield put(actions.getAnalyticsMembersSuccess({ period, data: response, labelId }));
  } else {
    yield put(actions.getAnalyticsMembersFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetAnalyticsUsers({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { period, labelId } = payload;
  const { ok, error, response } = yield apiCall(api.workspace.analytics.getUsers, { workspaceId, ...payload });
  if (ok) {
    yield put(actions.getAnalyticsUsersSuccess({ period, data: response, labelId }));
  } else {
    yield put(actions.getAnalyticsUsersFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetAnalyticsActiveness({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { period, labelId } = payload;
  const { ok, error, response } = yield apiCall(api.workspace.analytics.getActiveness, { workspaceId, ...payload });
  if (ok) {
    yield put(actions.getAnalyticsActivenessSuccess({ period, data: response, labelId }));
  } else {
    yield put(actions.getAnalyticsActivenessFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetAnalyticsEngagement({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { period, labelId } = payload;
  const { ok, error, response } = yield apiCall(api.workspace.analytics.getEngagement, { workspaceId, ...payload });
  if (ok) {
    yield put(actions.getAnalyticsEngagementSuccess({ period, data: response, labelId }));
  } else {
    yield put(actions.getAnalyticsEngagementFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetAnalyticsAdoption({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { period, labelId } = payload;
  const { ok, error, response } = yield apiCall(api.workspace.analytics.getAdoption, { workspaceId, ...payload });
  if (ok) {
    yield put(actions.getAnalyticsAdoptionSuccess({ period, data: response, labelId }));
  } else {
    yield put(actions.getAnalyticsAdoptionFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onSearch({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, error, response } = yield apiCall(api.workspace.search, { workspaceId, ...payload });

  if (ok) {
    yield put(actions.workspaceSearchSuccess(response));
  } else {
    yield put(actions.workspaceSearchFailure(error));

  }
}

function* onGetMemberRoles() {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, error, response } = yield apiCall(api.workspace.roles.index, { workspaceId });
  if (ok) {
    yield put(actions.getMemberRolesSuccess(response));
  } else {
    yield put(actions.getMemberRolesFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetContactDirectory({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { request, abort } = yield race({
    request: apiCall(api.workspace.getContactDirectory, { workspaceId, ...payload }),
    abort: take(types.getContactDirectory),
  });

  if (abort) {
    return;
  }

  const { ok, error, response } = request;

  if (ok) {
    yield put(actions.getContactDirectorySuccess(response));
  } else {
    yield put(actions.getContactDirectoryFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onSearchAutocomplete({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, error, response } = yield apiCall(api.workspace.search, { workspaceId, ...payload });

  if (ok) {
    yield put(actions.workspaceSearchAutocompleteSuccess(response));
  } else {
    yield put(actions.workspaceSearchAutocompleteFailure(error));
  }
}

export default function* workspaceSagas() {
  yield all([
    takeLeading(types.getWorkspaceChannels, onGetWorkspaceChannels),
    takeLeading(types.getGroups, onGetWorkspaceGroups),

    debounce(200, types.getMembers, onGetMembers),
    takeLeading(types.exportMembers, onExportMembers),
    takeLeading(types.getMember, onGetMember),
    takeLeading(types.getLabels, onGetLabels),
    takeLeading(types.addMember, onAddMember),
    takeLeading(types.updateMember, onUpdateMember),
    takeLeading(types.deleteMember, onDeleteMember),
    takeLeading(types.reinviteMember, onReinviteMember),
    takeLeading(types.memberMassAction, onMemberMassAction),

    takeLeading(types.getUser, onGetUser),
    takeLeading(types.updateUser, onUpdateUser),
    takeLeading(types.getInvitation, onGetInvitation),
    takeLeading(types.regenerateInvitation, onRegenerateInvitation),

    takeLeading(types.getWorkspaceSettings, onGetWorkspaceSettings),
    takeLeading(types.updateWorkspaceSettings, onUpdateWorkspaceSettings),

    takeLeading(types.getWorkspaceProfile, onGetWorkspaceProfile),
    takeLeading(types.updateWorkspaceProfile, onUpdateWorkspaceProfile),

    takeLeading(types.getWorkspacePulseSettings, onGetWorkspacePulseSettings),
    takeLeading(types.updateWorkspacePulseSettings, onUpdateWorkspacePulseSettings),

    takeLeading(types.sendPulseFeedback, onSendPulseFeedback),

    debounce(500, types.getContactDirectory, onGetContactDirectory),

    takeEvery(types.getChallenges, onGetChallenges),

    takeLeading(types.getChallengeTemplates, onGetChallengeTemplates),
    takeLeading(types.updateChallenge, onUpdateChallenge),
    takeLeading(types.exportChallenge, onExportChallenge),
    takeLeading(types.storeChallenge, onStoreChallenge),
    takeLeading(types.endChallenge, onEndChallenge),

    debounce(200, types.getAnalyticsPulse, onGetAnalyticsPulse),
    debounce(200, types.getAnalyticsPulseExport, onGetAnalyticsPulseExport),
    debounce(200, types.getAnalyticsFunnel, onGetAnalyticsFunnel),
    debounce(200, types.getAnalyticsOverview, onGetAnalyticsOverview),
    debounce(200, types.getAnalyticsPosts, onGetAnalyticsPosts),
    debounce(200, types.getAnalyticsComments, onGetAnalyticsComments),
    debounce(200, types.getAnalyticsLikes, onGetAnalyticsLikes),
    debounce(200, types.getAnalyticsMembers, onGetAnalyticsMembers),
    debounce(200, types.getAnalyticsUsers, onGetAnalyticsUsers),
    debounce(200, types.getAnalyticsActiveness, onGetAnalyticsActiveness),
    debounce(200, types.getAnalyticsEngagement, onGetAnalyticsEngagement),
    debounce(200, types.getAnalyticsAdoption, onGetAnalyticsAdoption),

    takeLatest(types.workspaceSearch, onSearch),
    debounce(300, types.workspaceSearchAutocomplete, onSearchAutocomplete),
    takeLeading(types.getMemberRoles, onGetMemberRoles),
  ]);
}
