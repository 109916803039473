import memoize from 'lodash/memoize';
import get from 'lodash/get';
import takeWhile from 'lodash/takeWhile';
import { createSelector } from 'reselect';
import { authSelectors } from 'redux/auth';

const constructDeepKey = (...args) => takeWhile(args, v => !!v).join('.');

const getTranslations = ({ entities: { translations } }) => translations;
const getTranslationSelector = createSelector(
  [getTranslations, authSelectors.getLocale],
  (translations, locale) => memoize(

    (type, id, field = null, defaultValue = null) => {

      const selector = constructDeepKey(locale, type, id, field);

      return get(translations, selector, defaultValue);
    }, (...args) => args.join('.'),
  ),
);
export default {
  getTranslationSelector,
};
