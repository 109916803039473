import Avatar from 'components/Avatar';
import React, { useEffect } from 'react';
import TrophyIcon from 'components/Icons/Trophy';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import { challengeActions, challengeSelectors, challengeTypes } from 'redux/challenge';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from 'components/Grid';
import useReduxLoading from 'hooks/useReduxLoading';
import Skeleton from '@mui/material/Skeleton';
import { css } from '@emotion/react';
import Box from '@mui/material/Box';
import PropTypes from 'propTypes';
import { groupActions, groupSelectors, groupTypes } from 'redux/group';
import AvatarRow from 'components/AvatarRow';
import { folderActions, folderSelectors, folderTypes } from 'redux/documentFolder';
import FolderOpenRoundedIcon from '@mui/icons-material/FolderOpenRounded';
import { courseActions, courseSelectors, courseTypes } from 'redux/course';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import { feedbackActions, feedbackSelectors, feedbackTypes } from 'redux/feedback';
import RateReviewRoundedIcon from '@mui/icons-material/RateReviewRounded';
import { calendarActions, calendarSelectors, calendarTypes } from 'redux/calendar';
import { sanitizeGuidancePostExcerpt } from 'services/sanitizationService';
import TinyOpengraphPreview from './TinyOpengraphPreview';
import { Description, Thumbnail, Title } from './PreviewComponents';

const Wrapper = styled(Box)`
  padding: 0.5rem 0.75rem;
  min-height: 60px;
  display: flex;
  align-items: center;
  background: #fff;

  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;

  display: block;
  text-decoration: none;
  color: inherit;

  ${({ href, onClick }) => (href || onClick) && css`
    &:hover {
      background: #f7f7f7;
    }
  `}
`;

const ProgressBar = styled(LinearProgress)`
  max-width: 200px;
  width: 100%;
  border-radius: 4px;
  margin-right: 0.5rem;
  height: 6px;
`;

const ProgressBarWrapper = styled('div')`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-weight: 500;
  font-size: 0.75rem;
`;

const CommonLinkPreview = ({ left, title, description, right, ...rest }) => (
  <Wrapper {...rest}>
    <Grid container direction="row" spacing={1} alignItems="center" wrap="nowrap">
      {left && <Grid item>{left}</Grid>}
      <Grid item xs>
        <Grid container direction="column" wrap="nowrap" spacing={0.5}>
          <Grid item xs={12}>{title}</Grid>
          <Grid item xs={12}>{description}</Grid>
        </Grid>
      </Grid>
      {right && <Grid item>{right}</Grid>}
    </Grid>
  </Wrapper>
);

CommonLinkPreview.propTypes = {
  left: PropTypes.any,
  title: PropTypes.any,
  description: PropTypes.any,
  right: PropTypes.any,
};

const ChallengeLinkPreview = ({ image, title, description, guavaModelId, ...rest }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(challengeActions.getChallenge({ challengeId: guavaModelId }));
  }, [guavaModelId, dispatch]);

  const loading = useReduxLoading(
    [challengeTypes.getChallenge],
    [challengeTypes.getChallengeSuccess, challengeTypes.getChallengeFailure],
    true,
  );

  const challenge = useSelector(state => challengeSelectors.getChallenge(state, guavaModelId));

  if (!loading && !challenge) {
    return <TinyOpengraphPreview {...{ image, title, description }} />;
  }

  return (
    <CommonLinkPreview
      left={loading && !challenge?.id
        ? <Skeleton variant="circular" width={48} height={48} />
        : <Avatar size={48} src={challenge?.badge ?? image} fallbackIcon={<TrophyIcon fontSize="inherit" />} />}

      title={loading && !challenge?.id
        ? <Skeleton width={100} height={15} />
        : <Title>{challenge?.title ?? title}</Title>}

      description={loading && !challenge?.id
        ? <Skeleton width={200} height={12} />
        : <ProgressBarWrapper><ProgressBar variant="determinate" value={challenge?.progress} /> {challenge?.progress}%</ProgressBarWrapper>}

      {...rest}
    />
  );
};

const commonPropTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ src: PropTypes.string, srcSet: PropTypes.string }),
  ]),

  guavaModelId: PropTypes.any,
};

ChallengeLinkPreview.propTypes = commonPropTypes;


const GroupLinkPreview = ({ image, title, description, guavaModelId, ...rest }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(groupActions.get({ id: guavaModelId }));
  }, [guavaModelId, dispatch]);

  const loading = useReduxLoading(
    [groupTypes.get],
    [groupTypes.getSuccess, groupTypes.getFailure],
    true,
  );

  const group = useSelector(groupSelectors.getGroupSelector)(guavaModelId);

  if (!loading && !group) {
    return <TinyOpengraphPreview {...{ image, title, description }} />;
  }

  const avatarRow = group?.subscribers?.length > 0
    ? <AvatarRow size={24} max={4} users={group?.subscribers} total={group?.subscribersCount} />
    : null;

  return (
    <CommonLinkPreview
      left={loading && !group?.id
        ? <Skeleton variant="rounded" width={50} height={50} />
        : <Thumbnail src={group?.cover ?? image} />}

      title={loading && !group?.id
        ? <Skeleton width={100} height={15} />
        : <Title>{group?.title ?? title}</Title>}

      description={loading && !group?.id
        ? <Skeleton width={200} height={12} />
        : <Description>{group?.description ?? description}</Description>}

      right={loading && !group?.id
        ? <Skeleton width={60} height={24} />
        : avatarRow}

      {...rest}
    />
  );
};

GroupLinkPreview.propTypes = commonPropTypes;

const FolderLinkPreview = ({ image, title, description, guavaModelId, ...rest }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(folderActions.getFolderForMember({ folderId: guavaModelId }));
  }, [guavaModelId, dispatch]);

  const loading = useReduxLoading(
    [folderTypes.getFolderForMember],
    [folderTypes.getFolderForMemberSuccess, folderTypes.getFolderForMemberFailure],
    true,
  );

  const folder = useSelector(folderSelectors.getFolderForMember)(guavaModelId);

  if (!loading && !folder) {
    return <TinyOpengraphPreview {...{ image, title, description }} />;
  }

  return (
    <CommonLinkPreview
      left={loading && !folder?.id
        ? <Skeleton variant="circle" width={50} height={50} />
        : <Avatar size={50} src={folder.cover?.src} srcSet={folder.cover?.srcSet} name={folder.name}><FolderOpenRoundedIcon /></Avatar>}

      title={loading && !folder?.id
        ? <Skeleton width={100} height={15} />
        : <Title>{folder?.name ?? title}</Title>}

      description={loading && !folder?.id
        ? <Skeleton width={200} height={12} />
        : <Description>{folder?.description ?? description}</Description>}

      {...rest}
    />
  );
};

FolderLinkPreview.propTypes = commonPropTypes;

const CourseLinkPreview = ({ image, title, description, guavaModelId, ...rest }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(courseActions.getCourseForMember({ courseId: guavaModelId }));
  }, [guavaModelId, dispatch]);

  const loading = useReduxLoading(
    [courseTypes.getCourseForMember],
    [courseTypes.getCourseForMemberSuccess, courseTypes.getCourseForMemberFailure],
    true,
  );

  const course = useSelector(courseSelectors.getCourseForMember)(guavaModelId);

  if (!loading && !course) {
    return <TinyOpengraphPreview {...{ image, title, description }} />;
  }

  return (
    <CommonLinkPreview
      left={loading && !course?.id
        ? <Skeleton variant="circle" width={50} height={50} />
        : <Avatar size={50} src={course.cover?.src} srcSet={course.cover?.srcSet} name={course.name}><SchoolRoundedIcon /></Avatar>}

      title={loading && !course?.id
        ? <Skeleton width={100} height={15} />
        : <Title>{course?.name ?? title}</Title>}

      description={loading && !course?.id
        ? <Skeleton width={200} height={12} />
        : <Description>{course?.description ?? description}</Description>}

      {...rest}
    />
  );
};

CourseLinkPreview.propTypes = commonPropTypes;

const FeedbackTypeLinkPreview = ({ image, title, description, guavaModelId, ...rest }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(feedbackActions.getFeedbackType({ feedbackTypeId: guavaModelId }));
  }, [guavaModelId, dispatch]);

  const loading = useReduxLoading(
    [feedbackTypes.getFeedbackType],
    [feedbackTypes.getFeedbackTypeSuccess, feedbackTypes.getFeedbackTypeFailure],
    true,
  );

  const feedbackType = useSelector(feedbackSelectors.getFeedbackTypeSelector)(guavaModelId);

  if (!loading && !feedbackType) {
    return <TinyOpengraphPreview {...{ image, title, description }} />;
  }

  return (
    <CommonLinkPreview
      left={loading && !feedbackType?.id
        ? <Skeleton variant="circle" width={50} height={50} />
        : <Avatar size={50} name={feedbackType.name}><RateReviewRoundedIcon /></Avatar>}

      title={loading && !feedbackType?.id
        ? <Skeleton width={100} height={15} />
        : <Title>{feedbackType?.name ?? title}</Title>}

      description={loading && !feedbackType?.id
        ? <Skeleton width={200} height={12} />
        : <Description>{feedbackType?.description ?? description}</Description>}

      {...rest}
    />
  );
};

FeedbackTypeLinkPreview.propTypes = commonPropTypes;

const EventLinkPreview = ({ image, title, description, guavaModelId, ...rest }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(calendarActions.getEvent({ eventId: guavaModelId }));
  }, [guavaModelId, dispatch]);

  const loading = useReduxLoading(
    [calendarTypes.getEvent],
    [calendarTypes.getEventSuccess, calendarTypes.getEventFailure],
    true,
  );

  const event = useSelector(calendarSelectors.getEventSelector)(guavaModelId);

  if (!loading && !event) {
    return <TinyOpengraphPreview {...{ image, title, description }} />;
  }

  return (
    <CommonLinkPreview
      left={loading && !event?.id
        ? <Skeleton variant="rounded" width={50} height={50} />
        : <Thumbnail src={event?.cover ?? image} />}

      title={loading && !event?.id
        ? <Skeleton width={100} height={15} />
        : <Title>{event?.name ?? title}</Title>}

      description={loading && !event?.id
        ? <Skeleton width={200} height={12} />
        : <Description>{event?.description ? sanitizeGuidancePostExcerpt(event?.description) : description}</Description>}

      {...rest}
    />
  );
};

EventLinkPreview.propTypes = commonPropTypes;


const InternalOpengraphPreview = ({ guavaModelType, ...rest }) => {
  switch (guavaModelType) {
    case 'challenge':
      return <ChallengeLinkPreview {...rest} />;
    case 'group':
      return <GroupLinkPreview {...rest} />;
    case 'folder':
      return <FolderLinkPreview {...rest} />;
    case 'course':
      return <CourseLinkPreview {...rest} />;
    case 'feedback_type':
      return <FeedbackTypeLinkPreview {...rest} />;
    case 'event':
      return <EventLinkPreview {...rest} />;
    default:
      return <TinyOpengraphPreview {...rest} />;
  }
};

InternalOpengraphPreview.propTypes = {
  guavaModelType: PropTypes.string,
};

export default InternalOpengraphPreview;
