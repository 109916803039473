import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import TextLoader from 'components/TextLoader';
import styled from '@emotion/styled/macro';
import { authSelectors } from 'redux/auth';
import { useTranslation } from 'react-i18next';
import usePrevious from 'hooks/usePrevious';

const StyledLoader = styled(TextLoader)`
  margin-top: 2rem;
`;

const AuthRoute = ({ auth: requiresAuth, ...args }) => {
  const { t } = useTranslation(['common']);

  const isLoggedIn = useSelector(authSelectors.getUserIsLoggedIn);
  const isLoading = useSelector(authSelectors.getIsLoading);

  const wasLoggedIn = usePrevious(isLoggedIn);

  if (isLoading) {
    return <StyledLoader text={t('generic.loading')} />;
  }

  if (isLoggedIn === requiresAuth) {
    return <Route {...args} />;
  }

  if (!isLoggedIn) {
    return wasLoggedIn
      ? <Redirect to={{ pathname: '/login' }} />
      : <Redirect to={{ pathname: '/login', state: { redirect: args.location } }} />;
  }

  return (
    <Redirect to={args.location?.state?.redirect || { pathname: '/' }} />
  );
};

AuthRoute.propTypes = {
  auth: PropTypes.bool,
};

AuthRoute.defaultProps = {
  auth: true,
};

export default AuthRoute;
