import React from 'react';
import TextLoader from 'components/TextLoader';
import InfiniteScroll from 'react-infinite-scroller';
import PropTypes from 'prop-types';
import EmptyState from 'components/EmptyState';
import EndOfSomething from 'components/EndOfSomething';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import { format, getDateString, isBirthday, isThisYear } from 'services/date-fns';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import MemberListItem from './MemberListItem';

const StyledListSubHeader = styled(ListSubheader)`
  color: #666;
  font-weight: 500;
  font-size: 0.8rem;
  text-transform: uppercase;
`;

const CenteredListItem = styled(ListItem)`
  justify-content: center;
`;

const MemberList = ({ members, loadMore, hasMore, currentPage, texts = {}, ...rest }) => {
  const { t } = useTranslation(['component', 'common']);

  const { loading, loadingMore, noEvents, noMoreEvents } = texts;

  const loaderProps = {
    loadMore,
    hasMore,
    loader: <TextLoader key="0" text={members.length === 0 && hasMore ? loading : loadingMore} />,
  };

  let currentTitle = '';

  return (
    <InfiniteScroll {...loaderProps} {...rest}>
      <List>
        {members.map(member => {
          const eventDate = new Date(member.nextJobStartAnniversary);

          // Common title
          const basicTitle = isThisYear(eventDate)
            ? format(eventDate, 'MMMM')
            : getDateString(eventDate, { month: 'long', year: 'numeric' }, 'MMMM');

          // Alternative for today
          const requiredTitle = isBirthday(eventDate)
            ? t('common:generic.today')
            : basicTitle;

          const withTitle = requiredTitle !== currentTitle;

          if (withTitle) {
            currentTitle = requiredTitle;
          }

          return (
            <React.Fragment key={member.id}>
              {withTitle && <StyledListSubHeader>{requiredTitle}</StyledListSubHeader>}
              <MemberListItem member={member} />
            </React.Fragment>
          );
        })}

        {!hasMore && members.length === 0 && <CenteredListItem><EmptyState title={noEvents} /></CenteredListItem>}
        {!hasMore && members.length > 0 && <CenteredListItem><EndOfSomething>{noMoreEvents}</EndOfSomething></CenteredListItem>}
      </List>
    </InfiniteScroll>
  );
};

MemberList.propTypes = {
  members: PropTypes.arrayOf(PropTypes.object),
  loadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  currentPage: PropTypes.number,

  texts: PropTypes.shape({
    loading: PropTypes.string.isRequired,
    loadingMore: PropTypes.string.isRequired,
    noEvents: PropTypes.string.isRequired,
    noMoreEvents: PropTypes.string.isRequired,
  }).isRequired,
};

MemberList.defaultProps = {
  members: [],
};

export default MemberList;
