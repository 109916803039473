import { all, call, put, select, takeLatest, takeLeading } from 'redux-saga/effects';
import { api, apiCall } from 'redux/helpers/api';
import { takeLeadingPerKey } from 'redux/helpers/saga';
import history from 'providers/history';
import types from './types';
import actions from './actions';
import selectors from './selectors';
import { snackbarActions } from '../snackbar';

function* onGetCoursesForMember({ payload }) {
  const { ok, response, error } = yield apiCall(api.learning.getCourses, payload);

  if (ok) {
    yield put(actions.getCoursesForMemberSuccess({ filter: payload.filter, ...response }));
  } else {
    yield put(actions.getCoursesForMemberFailure(error));
  }
}

function* onGetCourseForMember({ payload }) {
  const { ok, response, error } = yield apiCall(api.learning.getCourse, payload);

  if (ok) {
    yield put(actions.getCourseForMemberSuccess(response));
  } else {
    yield put(actions.getCourseForMemberFailure(error));
  }
}

function* onGetCoursesForAdmin({ payload }) {
  const { ok, response, error } = yield apiCall(api.learning.getCoursesForAdmin, payload);

  if (ok) {
    yield put(actions.getCoursesForAdminSuccess(response));
  } else {
    yield put(actions.getCoursesForMemberFailure(error));
  }
}

function* onGetCourseForAdmin({ payload }) {
  const { ok, response, error } = yield apiCall(api.learning.getCourseForAdmin, payload);

  if (ok) {
    yield put(actions.getCourseForAdminSuccess(response));
  } else {
    yield put(actions.getCourseForAdminFailure(error));
  }
}

function* onPostCourseForAdmin({ payload }) {
  const { ok, response, error } = yield apiCall(api.learning.postCourseForAdmin, payload);

  if (ok) {
    yield put(actions.postCourseForAdminSuccess(response));
    yield put(snackbarActions.createSuccess('courses.successfulPostCourse', { translate: true }));
  } else {
    yield put(actions.postCourseForAdminFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onPutCourseForAdmin({ payload }) {
  const { ok, response, error } = yield apiCall(api.learning.putCourseForAdmin, payload);

  if (ok) {
    yield put(actions.putCourseForAdminSuccess(response));
    yield put(snackbarActions.createSuccess('courses.successfulPutCourse', { translate: true }));
  } else {
    yield put(actions.putCourseForAdminFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onDeleteCourseVariantForAdmin({ payload }) {
  const {
    ok,
    error,
  } = yield apiCall(api.learning.deleteCourseVariantForAdmin, payload);

  if (ok) {
    yield put(actions.deleteCourseVariantForAdminSuccess({
      courseId: payload.courseId,
      courseVariantId: payload.courseVariantId,
    }));
    yield put(snackbarActions.createSuccess('courses.successfulDeleteCourse', { translate: true }));

    const courseVariantsSelector = yield select(selectors.getCourseVariantsForAdmin);
    const remainingCourseVariants = yield call(courseVariantsSelector, payload.courseId);

    const redirectUrl = remainingCourseVariants.length > 0 ? `/manage/e-learning/course/${payload.courseId}/variants` : '/manage/e-learning';
    yield call(history.push, redirectUrl);

  } else {
    yield put(actions.deleteCourseVariantForAdminFailure(error));
    yield put(snackbarActions.createFailure('courses.unsuccessfulDeleteCourse', { translate: true }));
  }
}

function* onDuplicateCourseVariantForAdmin({ payload: { courseId, noRedirection, courseVariantId, ...rest } }) {
  const { ok, response, error } = yield apiCall(api.learning.duplicateCourseVariantForAdmin, { courseVariantId, ...rest });

  if (ok) {
    yield put(actions.duplicateCourseVariantForAdminSuccess(response));
    yield put(snackbarActions.createSuccess('courses.successfulDuplicateCourseVariant', { translate: true }));

    yield put(actions.getCourseForAdmin({ courseId }));

    if (!noRedirection) {
      history.push(`/manage/e-learning/course/${courseId}/variant/${response.data}/edit/lessons`);
    }
  } else {
    yield put(actions.duplicateCourseVariantForAdminFailure(error));
    yield put(snackbarActions.createFailure('courses.unsuccessfulDuplicateCourseVariant', { translate: true }));
  }
}

function* onArchiveCourseVariantForAdmin({ payload }) {
  const { ok, response, error } = yield apiCall(api.learning.archiveCourseVariantForAdmin, { courseVariantId: payload.courseVariantId });

  if (ok) {
    yield put(actions.archiveCourseVariantForAdminSuccess(response));
  } else {
    yield put(actions.archiveCourseVariantForAdminFailure(error));
  }
}


export default function* courseSagas() {
  yield all([
    takeLeadingPerKey(types.getCoursesForMember, onGetCoursesForMember, ({ payload }) => payload.filter),
    takeLeadingPerKey(types.getCourseForMember, onGetCourseForMember, ({ payload }) => payload.courseId),
    takeLatest(types.getCoursesForAdmin, onGetCoursesForAdmin),
    takeLeadingPerKey(types.getCourseForAdmin, onGetCourseForAdmin, ({ payload }) => payload.courseId),
    takeLeading(types.postCourseForAdmin, onPostCourseForAdmin),
    takeLeading(types.putCourseForAdmin, onPutCourseForAdmin),
    takeLeading(types.deleteCourseVariantForAdmin, onDeleteCourseVariantForAdmin),
    takeLeading(types.duplicateCourseVariantForAdmin, onDuplicateCourseVariantForAdmin),
    takeLeading(types.archiveCourseVariantForAdmin, onArchiveCourseVariantForAdmin),
  ]);
}
