import React, { useState, useMemo, useEffect, useCallback, useRef, useContext, useLayoutEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SettersContext from '../Contexts/SettersContext';
import ContactDirectoryListItem from './ContactDirectoryListItem';

const StyledTreeView = styled(TreeView)`
  && .MuiTreeItem-content {
    padding: 0px 1rem;
  }

  && .MuiTreeItem-label {
    margin: 0.2rem 0;
    font-weight: 700;
  }

  .MuiTreeItem-group {
    margin-left: calc(1.5rem - 1px);
    border-left: 2px solid #f5f5f5;
  }

  ${({ depth }) => depth === 0 && css`
    && > li > .MuiTreeItem-content {
      padding: 0 1rem;
    }

    && > li > .MuiTreeItem-group {
      margin-left: calc(1rem + 0.5rem - 1px);
    }
  `}
`;

const UnpaddedList = styled(List)`
  padding: 0;
`;

const TreeWorker = ({ nodes, preferCollapsed, nodeId = 'root', depth = 0, ...rest }) => {

  const { t } = useTranslation(['component']);

  const [expanded, setExpanded] = useState([]);
  const all = useRef(new Set());

  const onNodeToggle = (e, ids) => {
    setExpanded(ids);
  };

  useEffect(() => {
    setExpanded(preferCollapsed ? [] : Array.from(all.current));
  }, [preferCollapsed]);

  const generateKey = useCallback((key) => {
    const next = `${nodeId}.${key}`;
    all.current.add(next);

    if (!preferCollapsed) {
      setExpanded(Array.from(all.current));
    }

    return next;
  }, [nodeId, preferCollapsed]);

  const render = useMemo(() => {
    return Object.keys(nodes || {})
      .filter(key => key !== '$items')
      .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
      .map((key) => (
        <TreeItem nodeId={generateKey(key)} key={generateKey(key)} label={key}>
          <TreeWorker nodes={nodes[key]} nodeId={generateKey(key)} preferCollapsed={preferCollapsed} depth={depth + 1} />
        </TreeItem>
      ));
  }, [depth, generateKey, nodes, preferCollapsed]);

  const memberRender = useMemo(() => {
    const $nodeId = generateKey('$items');
    const $items = nodes?.$items;

    if (!$items?.length) return null;

    const content = (
      <UnpaddedList key={$nodeId}>
        {$items.map(({ type: itemType, id: itemId }) => <ContactDirectoryListItem key={itemType + itemId} itemType={itemType} itemId={itemId} />)}
      </UnpaddedList>
    );

    return depth === 0
      ? <TreeItem nodeId={$nodeId} key={$nodeId} label={t('contactDirectory.uncategorized')}>{content}</TreeItem>
      : content;
  }, [depth, generateKey, nodes?.$items, t]);

  return (
    <StyledTreeView
      disableSelection
      disabledItemsFocusable={false}
      expanded={expanded}
      onNodeToggle={onNodeToggle}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}

      depth={depth}
      {...rest}
    >
      {render}
      {memberRender}
    </StyledTreeView>
  );
};

TreeWorker.propTypes = {
  nodes: PropTypes.object.isRequired,
  preferCollapsed: PropTypes.bool,
  nodeId: PropTypes.string,
  depth: PropTypes.number,
};

TreeWorker.defaultProps = {
  nodeId: 'root',
  depth: 0,
};

const ContactDirectoryTree = ({ items, ...rest }) => {

  const { t } = useTranslation(['component']);

  const { setButtons } = useContext(SettersContext);

  const [preferCollapsed, setPreferCollapsed] = useState(false);

  const renderButton = useCallback(() => {
    const onClick = () => {
      setPreferCollapsed(current => !current);
      renderButton();
    };

    const title = preferCollapsed === false ? t('component:contactDirectory.collapseAllButton') : t('component:contactDirectory.expandAllButton');
    const Icon = preferCollapsed === false ? UnfoldLessIcon : UnfoldMoreIcon;

    setButtons(<IconButton fontSize="small" onClick={onClick} title={title} aria-label={title}><Icon /></IconButton>);
  }, [preferCollapsed, setButtons, t]);

  useLayoutEffect(() => {
    renderButton();
    return () => setButtons(null);
  }, [renderButton, setButtons, t]);

  return (
    <InfiniteScroll key={preferCollapsed ? 'collapsed' : 'not-collapsed'} {...rest}>
      <TreeWorker nodes={items} preferCollapsed={preferCollapsed} />
    </InfiniteScroll>
  );
};

ContactDirectoryTree.propTypes = {
  items: PropTypes.object.isRequired,
};

export default ContactDirectoryTree;
