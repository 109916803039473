import React from 'react';
import PropTypes from 'prop-types';
import MuiAvatar from '@mui/material/Avatar';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import stringService from 'services/stringService';
import UserIcon from 'components/Icons/User';

const StyledAvatar = styled(MuiAvatar)`
  text-transform: uppercase;
  text-decoration: none;

  color: ${({ theme }) => theme?.palette?.primary?.contrastText} !important;

  ${({ onClick }) => onClick && 'cursor: pointer;'}

  ${({ size }) => size && css`
    width: ${size}px;
    height: ${size}px;
    font-size: ${Math.max(Math.ceil(size / 2.5), 10)}px;
    letter-spacing: -1px;
  `}
`;

const Avatar = ({ name, firstName, lastName, fallbackIcon, sizes, children, ...rest }) => {

  const fullNameParts = name ? [name] : [firstName, lastName];
  const fullName = fullNameParts.filter(v => !!v).join(' ') || undefined;

  const content = stringService.getInitials(fullName) || fallbackIcon;

  return (
    <StyledAvatar title={fullName} alt={fullName} sizes={sizes || `${rest.size}px`} {...rest}>
      {children || content}
    </StyledAvatar>
  );
};

Avatar.propTypes = {
  size: PropTypes.number, // The required height/Width in pixels

  src: PropTypes.string,
  srcSet: PropTypes.string,
  sizes: PropTypes.string,

  name: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,

  workspace: PropTypes.bool,

  fallbackIcon: PropTypes.any,
};

Avatar.defaultProps = {
  fallbackIcon: <UserIcon fontSize="inherit" />,
};

export default Avatar;
