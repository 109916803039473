import React, { useState, useRef, useEffect, useContext } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Button from 'components/Button';
import SettingsIcon from 'components/Icons/Settings';
import { useField, useFormState } from 'react-final-form';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';
import PollViewContext from './PollViewContext';

const PollSettingMenuItem = ({ label, checked, disabled, onChange }) => (
  <MenuItem>
    <FormControlLabel
      control={<Checkbox />}
      label={label}
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      margin="none"
      style={{ marginRight: 0 }}
    />
  </MenuItem>
);

PollSettingMenuItem.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

const PollSettingListItem = ({ label, checked, onChange }) => (
  <ListItem onClick={onChange}>
    <ListItemIcon><Checkbox checked={checked} /></ListItemIcon>
    <ListItemText>{label}</ListItemText>
  </ListItem>
);

PollSettingListItem.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

const PollSettingsButton = ({ variant, fieldPrefix, hideButtons }) => {

  const { t } = useTranslation('component');

  const button = useRef();

  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen(c => !c);

  const onClickAway = () => setOpen(false);

  const { initialValues } = useFormState({
    subscription: {
      initialValues: true,
    },
  });

  const { closeAutomatically, setCloseAutomatically } = useContext(PollViewContext);

  useEffect(() => {
    setCloseAutomatically(!!initialValues?.poll?.closeAt);
  }, [initialValues, setCloseAutomatically]);

  // https://github.com/final-form/react-final-form/issues/858
  // Would use CheckboxField directly for max_user_votes,
  // but this is not working correctly:
  // const maxVotesProps = {
  //   defaultValue: null,
  //   parse: v => v ? null : 1, // Checked: null, no limit. Unchecked: 1.
  //   format: v => v === null,
  // };

  const prefix = fieldPrefix ? `${fieldPrefix}` : '';

  // Make sure these values are represented even if the Popper is never opened.
  // For some reason Popper[keepMounted] didn't seem to work...
  const { input: { value: maxVotes, onChange: onChangeMaxVotes } } = useField(`${prefix}maxVotes`, { defaultValue: null, allowNull: true });
  const { input: { value: addOptions, onChange: setAddOptions } } = useField(`${prefix}addOptions`, { defaultValue: true });
  const { input: { value: anonymous, onChange: setAnonymous } } = useField(`${prefix}anonymous`, { defaultValue: false });
  const { input: { value: hideVotes, onChange: setHideVotes } } = useField(`${prefix}hideVotes`, { defaultValue: false });

  const onHideVotesChange = () => {
    const next = !hideVotes;
    if (next) {
      setAnonymous(false);
    }
    setHideVotes(next);
  };

  let items = [
    {
      key: 'multiple',
      label: t('pollForm.settings.multipleAnswersLabel'),
      checked: maxVotes === null,
      onChange: () => onChangeMaxVotes(maxVotes ? null : 1),
    },
    {
      key: 'addOptions',
      label: t('pollForm.settings.addOptionsLabel'),
      checked: addOptions,
      onChange: () => setAddOptions(!addOptions),
    },
    {
      key: 'anonymous',
      label: t('pollForm.settings.anonymouseLabel'),
      disabled: hideVotes,
      checked: !hideVotes && anonymous,
      onChange: () => setAnonymous(!anonymous),
    },
    {
      key: 'hideVotes',
      label: t('pollForm.settings.hideVotesLabel'),
      checked: hideVotes,
      onChange: onHideVotesChange,
    },
    {
      key: 'closeAt',
      label: t('pollForm.settings.closeAutomaticallyLabel'),
      checked: closeAutomatically,
      onChange: () => setCloseAutomatically(c => !c),
    },
  ];

  if (hideButtons?.length > 0) {
    items = items.filter(({ key }) => !hideButtons.includes(key));
  }

  return (
    <>
      <Button color="default" ref={button} onClick={toggleOpen} nowrap startIcon={<SettingsIcon />}>{t('pollForm.settings.button')}</Button>
      {variant === 'menu' && (
        <Popper open={open} anchorEl={button.current} transition placement="bottom-start" style={{ zIndex: 1300 }}>
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={onClickAway}>
                  <MenuList id="poll-settings-menu">
                    {items.map(({ key, ...item }) => <PollSettingMenuItem key={key} {...item} />)}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
      {variant === 'drawer' && (
        <Drawer anchor="bottom" open={open} onClose={() => setOpen(false)}>
          <List>
            {items.map(({ key, ...item }) => <PollSettingListItem key={key} {...item} />)}
          </List>
        </Drawer>
      )}
    </>
  );
};

PollSettingsButton.propTypes = {
  variant: PropTypes.oneOf(['menu', 'drawer']),
  hideButtons: PropTypes.arrayOf(PropTypes.oneOf(['multiple', 'addOptions', 'anonymous', 'hideVotes', 'closeAt'])),
  fieldPrefix: PropTypes.string,
};

PollSettingsButton.defaultProps = {
  variant: 'menu',
};

export default PollSettingsButton;
