import React from 'react';
import { Field } from 'react-final-form';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { isValid, formatRFC3339 } from 'services/date-fns';
import fieldRenderProps from 'components/FinalForm/fieldRenderProps';
import getErrorProperties from 'components/FinalForm/getErrorProperties';

const parse = (str) => {
  if (!str) {
    return str;
  }

  const date = new Date(str);
  return isValid(date) ? formatRFC3339(date) : str;
};

const DateTimePickerBase = ({ input: { value, onChange, ...input }, InputLabelProps, meta, fullWidth, margin, endAdornment, ...rest }) => {

  const { t } = useTranslation(['common']);

  const errorProps = getErrorProperties(meta);

  return (
    <DateTimePicker
      value={value}
      onChange={onChange}
      disableMaskedInput
      todayText={t('common:datepicker.now', 'Now')}
      okText={t('common:datepicker.ok', 'Ok')}
      clearText={t('common:datepicker.clear', 'Clear')}
      cancelText={t('common:datepicker.cancel', 'Cancel')}
      renderInput={props => (
        <TextField
          fullWidth={fullWidth}
          margin={margin}
          {...input}
          {...props}
          InputLabelProps={{
            ...input.InputLabelProps,
            ...props.InputLabelProps,
            ...InputLabelProps,
          }}
          InputProps={{
            ...input.InputProps,
            ...props.InputProps,
            endAdornment: <>{props?.InputProps?.endAdornment} {endAdornment}</>,
          }}
          error={errorProps.error || ((!!value || (input.required && meta.submitFailed)) && props.error)}
          helperText={errorProps.helperText || props.helperText}
        />
      )}
      {...rest}
    />
  );
};

DateTimePickerBase.propTypes = {
  ...fieldRenderProps,
};

const DateTimePickerField = ({ ...rest }) => <Field allowNull parse={parse} {...rest} />;

DateTimePickerField.defaultProps = {
  component: DateTimePickerBase,
  type: 'string',
  fullWidth: true,
};

export default DateTimePickerField;
